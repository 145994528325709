import * as actions from '../actions/calendarActions'

export const initialState = {
  calendars: [],
  loading: false,
  hasErrors: false,
  calendarCreated: false
};

export function calendarsReducer(state = initialState, action) {
  switch (action.type) {
      case actions.GET_CALENDARS:
          return {...state, loading: true};
      case actions.GET_CALENDARS_SUCCESS:
        return {calendars: action.payload, loading: false, hasErrors: false};
      case actions.GET_CALENDARS_FAILURE:
        return {...state, loading: false, hasErrors: true};
    default:
      return state
  }
}

export function createCalendarReducer(state = initialState, action) {
  switch (action.type) {
      case actions.CREATE_CALENDAR:
          return {...state, loading: true};
      case actions.CREATE_CALENDAR_SUCCESS:
        return {calendarCreated: true, loading: false, hasErrors: false};
      case actions.CREATE_CALENDAR_FAILURE:
        return {...state, loading: false, hasErrors: true};
    default:
      return state
  }
}
