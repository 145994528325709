import * as actions from '../actions/schedulerActions'

export const initialState = {
  settings: [],
  loading: false,
  hasErrors: false,
};

export default function schedulerReducer(state = initialState, action) {
  switch (action.type) {
      case actions.GET_SETTINGS:
          return {...state, loading: true};
      case actions.GET_SETTINGS_SUCCESS:
        return {settings: action.payload, loading: false, hasErrors: false};
      case actions.GET_SETTINGS_FAILURE:
        return {...state, loading: false, hasErrors: true};
    default:
      return state
  }
}
