import React, {useState} from 'react'
import {connect} from 'react-redux'
import {createNewCalendar} from "../../actions/calendarActions";


const CreateListing = ({loading, hasErrors, closeModal, sendForm, calendarCreated}) => {
    const [listingData, setListingData] = useState({
        search_query: null,
        adults_count: null,
        pets_allowed: '0',
        days_count: '0'
    });

    const handleChange = (evt) => {
        listingData[evt.target.name] = evt.target.value;
        setListingData({
            ...listingData,
        })
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        sendForm(listingData);
    };

    return (
        <div>
            <div className="header-toolbox">
                <h1>Create calendar task</h1>
                <button className="btn btn-red" onClick={() => {
                    closeModal(false)
                }}>x
                </button>
            </div>
            <div className="bordered-container">
                <p className="error-message mb-3">Warning, calendar creation can take 6-10 minutes, do not
                    click on the creation button many times, wait till a window will close, or error will be raised!
                </p>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>Search Query</label>
                        <input type="text" className="form-control" required
                               name="search_query" onChange={handleChange}/>
                    </div>
                    <div className="form-group">
                        <label>Guest Count</label>
                        <input type="text" className="form-control" required
                               name="adults_count" onChange={handleChange}/>
                    </div>
                    <div className="form-group">
                        <label>Pets</label>
                        <select className="form-control" onChange={handleChange} name="pets_allowed">
                            <option value="0">No pets</option>
                            <option value="1">Pet allowed only</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label>Days between check-in and checkout</label>
                        <input type="text" className="form-control" required
                               name="days_count" onChange={handleChange}/>
                    </div>

                    {loading
                        ? <button type="submit" className="btn btn-green disabled-btn">Creating...</button>
                        : <button type="submit" className="btn btn-green">Create</button>
                    }

                    {calendarCreated
                        ? closeModal(false)
                        : null
                    }

                </form>
            </div>
        </div>
    )
};

const mapDispatchToProps = (dispatch) => {
    return {
        sendForm: (calendarData) => dispatch(createNewCalendar(calendarData))
    }
};

const mapStateToProps = state => ({
    loading: state.createCalendar.loading,
    hasErrors: state.createCalendar.hasErrors,
    calendarCreated: state.createCalendar.calendarCreated,

});

export default connect(mapStateToProps, mapDispatchToProps)(CreateListing)

