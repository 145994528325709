import axiosConfig from "../utils/api";

export const GET_CALENDARS = 'GET_CALENDARS';
export const GET_CALENDAR_CARD = 'GET_CALENDAR_CARD';
export const GET_CALENDARS_SUCCESS = 'GET_CALENDARS_SUCCESS';
export const GET_CALENDAR_CARD_SUCCESS = 'GET_CALENDAR_CARD_SUCCESS';
export const GET_CALENDARS_FAILURE = 'GET_CALENDARS_FAILURE';
export const GET_CALENDAR_CARD_FAILURE = 'GET_CALENDAR_CARD_FAILURE';
export const CHANGE_CARD_INDEX = 'CHANGE_CARD_INDEX';
export const CHANGE_LISTING_TYPE = 'CHANGE_LISTING_TYPE';


export const changeCalendarIndex = cardIndex => ({
    type: CHANGE_CARD_INDEX,
    newIndex: cardIndex
});

export const changeListingsTypes = listingType => ({
    type: CHANGE_LISTING_TYPE,
    listingType: listingType
});

export const getCalendars = () => ({
    type: GET_CALENDARS
});

export const getCalendarCard = () => ({
    type: GET_CALENDAR_CARD
});

export const getCalendarsSuccess = calendars => ({
    type: GET_CALENDARS_SUCCESS,
    payload: calendars
});

export const getCalendarCardSuccess = calendarCard => ({
    type: GET_CALENDAR_CARD_SUCCESS,
    payload: calendarCard
});

export const getCalendarsFailure = () => ({
    type: GET_CALENDARS_FAILURE
});

export const getCalendarCardFailure = () => ({
    type: GET_CALENDAR_CARD_FAILURE
});

export function fetchCalendars(dates=null, limit = 25, offset) {
    return async dispatch => {
        dispatch(getCalendars());
        try {
            let response = null;
            if (dates) {
                response = await axiosConfig.get(`v1/getScrapedCalendars?start_date=${dates.dateFrom}&end_date=${dates.dateTo}&limit=${limit}`);
            } else {
                response = await axiosConfig.get(`v1/getScrapedCalendars?limit=${limit}`);
            }

            const data = await response.data;
            dispatch(getCalendarsSuccess(data))
        } catch (error) {
            dispatch(getCalendarsFailure())
        }
    }
}

export function fetchCalendar(calendarId) {
    return async dispatch => {
        dispatch(getCalendarCard());
        try {
            const response = await axiosConfig.get(`getCalendarMeta?calendar_id=${calendarId}`);
            const data = await response.data;
            dispatch(getCalendarCardSuccess(data))
        } catch (error) {
            dispatch(getCalendarCardFailure())
        }
    }
}
export function handleCalendarIndex(calendarIndex) {
    return async dispatch => {
        dispatch(changeCalendarIndex(calendarIndex))
    }
}
export function handleListingsTypes(listingType) {
    return async dispatch => {
        console.log(listingType)
        dispatch(changeListingsTypes(listingType))
    }
}

export const CREATE_CALENDAR = 'CREATE_CALENDAR';
export const CREATE_CALENDAR_SUCCESS = 'CREATE_CALENDAR_SUCCESS';
export const CREATE_CALENDAR_FAILURE = 'CREATE_CALENDAR_FAILURE';

export const createCalendar = () => ({
    type: CREATE_CALENDAR
});

export const createCalendarSuccess = () => ({
    type: CREATE_CALENDAR_SUCCESS
});

export const createCalendarFailure = () => ({
    type: CREATE_CALENDAR_FAILURE
});

export function createNewCalendar(calendarData) {
    return async dispatch => {
        dispatch(createCalendar());
        try {
            console.log(calendarData);
            const response = await axiosConfig.post('creteCalendar?token=iuZ-012-990', calendarData);
            const data = await response.data;
            console.log(data);
            dispatch(createCalendarSuccess());
        } catch (error) {
            dispatch(createCalendarFailure())
        }
    }
}
