import * as firebase from "firebase/app";
import "firebase/auth";

const app = firebase.initializeApp({
    apiKey: "AIzaSyDaswudsUU0xJ8Y1QeDFVgUuaaP0pWT4jg",
    authDomain: "airbnb-dash.firebaseapp.com",
    databaseURL: "https://airbnb-dash.firebaseio.com",
    projectId: "airbnb-dash",
    storageBucket: "airbnb-dash.appspot.com",
    messagingSenderId: "881743851009",
    appId: "1:881743851009:web:efac5126f775021e8a9a00"
});

export default app;
