import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { fetchCurrentTaskSettings, updateCurrentTaskSettings } from "../../../actions/schedulerActions";
import HeaderBlock from "../../../сomponents/Header";
import PreloaderBlock from "../../../сomponents/Preloader";


const TaskSettings = ({getTaskData, sendForm, loading, settingsCard, hasErrors}) => {
    const [settings, setSettings] = useState(null);

    useEffect(() => {
            getTaskData()
        }, [getTaskData]);

    useEffect(() => {
            setSettings(settingsCard);
        });

    const handleChange = (evt) => {
        settings.task_params[evt.target.name] = evt.target.value;
        setSettings({
            ...settings,
        })
    };
    const handleCronChange = (evt) => {
        settings.cron_settings[evt.target.name] = evt.target.value;
        setSettings({
            ...settings,
        })
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        sendForm(settings)
    };

    const handleChecked = (event) => {
        const val = (event.target.value === 'on') ? false : true;
        settings.hide_prices = event.target.checked;
        console.log(settings.hide_prices)
        setSettings({
            ...settings,
        })
        console.log(event.target.value)
    }

    const renderTaskCard = () => {
        if (loading) return (
            <PreloaderBlock/>
        );
        if (settings !== null) {
            return (
            <div>
                <div className="header-toolbox">
                    <h1>Task <i>{settings.task_id}</i> settings</h1>
                </div>
                <div className="bordered-container">
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label>Task ID <sub className="sub-label">(can't edit)</sub></label>
                            <input type="text" className="form-control" disabled value={settings.task_id}/>
                        </div>

                        <div className="form-group">
                            <label>Search Query</label>
                            <input type="text" className="form-control" onChange={ handleChange }
                                   value={settings.task_params.query} name="query"/>
                        </div>

                        <div className="form-group">
                            <label>Hide prices</label>
                            <input
                            name="hide_prices"
                            type="checkbox"
                            checked={settings.hide_prices}
                            onChange={handleChecked} />
                            <input type="text" className="form-control" onChange={ handleChange }
                                   value={settings.hide_prices} name="hide_prices"/>
                        </div>

                        <div className="form-group">
                            <label>Days Stay</label>
                            <input type="text" className="form-control" onChange={ handleChange }
                                   value={settings.task_params.days_stay} name="days_stay"/>
                        </div>

                        <div className="form-group">
                            <label>Adults Count</label>
                            <input type="text" className="form-control" onChange={ handleChange }
                                   value={settings.task_params.adults_count} name="adults_count"/>
                        </div>

                        <div className="form-group">
                            <label>Pets Count</label>
                            <input type="text" className="form-control" onChange={ handleChange }
                                   value={settings.task_params.pets_count} name="pets_count"/>
                        </div>

                        <div className="form-group">
                            <label>Main email address</label>
                            <input type="text" className="form-control" onChange={ handleChange }
                                   value={settings.task_params.to_email} name="to_email"/>
                        </div>

                        <div className="form-group">
                            <label>Copy email address</label>
                            <input type="text" className="form-control" onChange={ handleChange }
                                   value={settings.task_params.cc_email} name="cc_email"/>
                        </div>

                        <div className="form-group mb-4">
                            <label>Additional email text</label>
                            <input type="text" className="form-control" onChange={ handleChange }
                                   value={settings.task_params.additional_text} name="additional_text"/>
                        </div>

                        <h3>Timing settings</h3>
                        <div className="form-group">
                            <label>Minute settings</label>
                            <input type="text" className="form-control" onChange={ handleCronChange }
                                   value={settings.cron_settings.minute} name="minute"/>
                        </div>
                        <div className="form-group">
                            <label>Hour settings</label>
                            <input type="text" className="form-control" onChange={ handleCronChange }
                                   value={settings.cron_settings.hour} name="hour"/>
                        </div>

                        <button type="submit" className="btn btn-green">Save settings</button>


                    </form>
                </div>
            </div>
        )}
        if (hasErrors) return <p>error</p>;
    };
    return (
        <div>
            <HeaderBlock/>
            <section className="main-section">
                {renderTaskCard()}
            </section>
        </div>
    )
};


const mapDispatchToProps = (dispatch) => {
    const taskID = window.location.pathname.split('/')[2];
    return {
        getTaskData: () => dispatch(fetchCurrentTaskSettings(taskID)),
        sendForm: (settings) => dispatch(updateCurrentTaskSettings(settings))
    }
};

const mapStateToProps = state => ({
    loading: state.currentTask.loading,
    settingsCard: state.currentTask.currentTask,
    hasErrors: state.currentTask.hasErrors
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskSettings)
