import React, {useEffect} from 'react'
import { connect } from 'react-redux'
import { fetchSettings } from "../../../actions/schedulerActions";
import HeaderBlock from "../../../сomponents/Header";
import PreloaderBlock from "../../../сomponents/Preloader"
import { SchedulerListItem } from './SchedulerListItem'


const SettingsList = ({dispatch, loading, settings, hasErrors}) => {
    useEffect(() => {
        dispatch(fetchSettings())
    }, [dispatch]);
    const renderSettings = () => {
        if (loading) return (
            <PreloaderBlock/>
        );
        if (hasErrors) return <p>error</p>;
        return (
            <div>
                <div className="header-toolbox">
                    <h1>Scheduled tasks manager</h1>
                </div>
                <div className="bordered-container">
                    <table className="calendars-list-table">
                        <thead>
                            <tr>
                                <th></th>
                                <th colSpan="4">Search params</th>
                            </tr>
                            <tr>
                                <th>Task name</th>
                                <th>Query</th>
                                <th>Days stay</th>
                                <th>Adults</th>
                                <th>Pets</th>
                            </tr>
                        </thead>
                        <tbody>
                        {settings.map(item => {
                            return (
                                <SchedulerListItem key={item.task_id} setting={item} />
                            )
                        })}
                        </tbody>
                    </table>
                </div>
            </div>
        )

    };
    return (
        <div>
            <HeaderBlock/>
            <section className="main-section">
                {renderSettings()}
            </section>
        </div>
    )
};

const mapStateToProps = state => ({
  loading: state.settings.loading,
  settings: state.settings.settings,
  hasErrors: state.settings.hasErrors,
});

export default connect(mapStateToProps)(SettingsList)
