import { combineReducers } from 'redux'
import { calendarsReducer, createCalendarReducer } from './calendarsReducer'
import calendarCardReducer from './calendarCardReducer'
import { listingsReducer, listingsGroupReducer, noGroupListingsReducer, deleteListingGroupReducer,
    listingsCountReducer, createListingReducer, deleteListingReducer, createListingGroupReducer, listingsChartsReducer } from './listingsReducer'

import tasksReducer from './tasksReducer'
import { proxiesReducer, proxiesCheckerReducer } from './proxiesReducer'
import schedulerReducer from './schedulerReducer'
import currentTaskReducer from './schedulerCardReducer'

const rootReducer = combineReducers({
  calendars: calendarsReducer,
  calendarCard: calendarCardReducer,
  createCalendar: createCalendarReducer,
  listings:  listingsReducer,
  listingsCharts:  listingsChartsReducer,
  listingsGroups: listingsGroupReducer,
  listingsWithoutGroups: noGroupListingsReducer,
  createListingGroup: createListingGroupReducer,
  deleteListingGroup: deleteListingGroupReducer,
  listingsCount: listingsCountReducer,
  createListing: createListingReducer,
  deleteListing: deleteListingReducer,
  tasks: tasksReducer,
  proxies: proxiesReducer,
  checkedProxies: proxiesCheckerReducer,
  settings: schedulerReducer,
  currentTask: currentTaskReducer,
});

export default rootReducer
