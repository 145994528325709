import React, {useEffect} from 'react'
import { connect } from 'react-redux'
import { fetchProxies, fetchCheckedProxies } from "../../../actions/proxiesActions";
import HeaderBlock from "../../../сomponents/Header";
import PreloaderBlock from "../../../сomponents/Preloader"
import { ProxyListItem } from './ProxyListItem'



const ProxiesList = ({dispatch, loading, checking, proxies, checkedProxies, hasErrors}) => {
    useEffect(() => {
        dispatch(fetchProxies())
    }, [dispatch]);
    const checkProxies = () => {
      dispatch(fetchCheckedProxies());
    };
    let brokenProxies = null;
    if (checkedProxies.length) {
        let broken = [];
        checkedProxies.map(proxy => {
            if (!proxy.status) {
             broken.push(proxy.proxy)
            }
        });
        brokenProxies = <p className="error-message"> Some proxies are broken, replace this proxies:  {JSON.stringify(broken)}</p>
    }
    const renderProxies = () => {
        if (loading) return (
            <PreloaderBlock/>
        );
        if (hasErrors) return <p>error</p>;
        return (
            <div>
                <div className="header-toolbox">
                    <h1>Proxies list</h1>
                    <div>
                        {!checking ?
                            <button className="btn btn-green" onClick={checkProxies}>Check proxies</button>
                            : <button className="btn btn-green disabled-btn" disabled>checking...</button>
                        }

                    </div>
                </div>
                <div className="bordered-container">
                    {brokenProxies}
                    <table className="calendars-list-table">
                        <thead>
                            <tr>
                                <th>End date</th>
                                <th>Proxy</th>
                            </tr>
                        </thead>
                        <tbody>
                        {proxies.map(proxy => {
                            return (
                                <ProxyListItem key={proxy.proxy} proxy={proxy} />
                            )
                        })}
                        </tbody>
                    </table>
                </div>
            </div>
        )

    };
    return (
        <div>
            <HeaderBlock/>
            <section className="main-section">
                {renderProxies()}
            </section>
        </div>
    )
};

const mapStateToProps = state => ({
  loading: state.proxies.loading,
  proxies: state.proxies.proxies,
  hasErrors: state.proxies.hasErrors,
  checkedProxies: state.checkedProxies.checkedProxies,
  checking: state.checkedProxies.loading,
});

export default connect(mapStateToProps)(ProxiesList)
