import * as actions from '../actions/proxiesActions'

export const initialState = {
    proxies: [],
    checkedProxies: [],
    loading: false,
    hasErrors: false,
};

const proxiesReducer = function(state = initialState, action) {
    switch (action.type) {
        case actions.GET_PROXIES:
            return {...state, loading: true};
        case actions.GET_PROXIES_SUCCESS:
            return {proxies: action.payload, loading: false, hasErrors: false};
        case actions.GET_PROXIES_FAILURE:
            return {...state, loading: false, hasErrors: true};
        default:
            return state
    }
}

const proxiesCheckerReducer = function (state = initialState, action) {
    switch (action.type) {
        case actions.CHECK_PROXIES:
            return {...state, loading: true};
        case actions.CHECK_PROXIES_SUCCESS:
            return {checkedProxies: action.payload};
        case actions.CHECK_PROXIES_FAILURE:
            return {...state, loading: false, hasErrors: true};
        default:
            return state
    }
};

export { proxiesReducer, proxiesCheckerReducer }
