import React, {useCallback, useRef} from "react";
import {useDndZone} from "react-dnd-action";

export function Column({name, items, onItemsChange, handleNameChange, columnIndex}) {
    const itemsContainerRef = useRef();
    const handleClick = useCallback(function () {
        alert("dragabble elements are still clickable :)");
    }, []);
    const handleSort = useCallback(
        function (e) {
            onItemsChange(e);
        },
        [onItemsChange]
    );

    useDndZone(
        itemsContainerRef,
        {items, flipDurationMs: 150},
        handleSort,
        handleSort
    );

    return (
        <div className={`${columnIndex === 0 ? 'ungrouped-listings-column' : 'sms-listings-group-item'} column `}>
            <div className='group-padding-wrapper'>
                {columnIndex === 0 ?
                    <h5>{name}</h5>
                    :
                    <input type={"text"} placeholder={name} onChange={handleNameChange}/>
                }
                <div className="column-content" ref={itemsContainerRef}>
                    {items.map((item) => (
                        <div key={item.id} className="card" onClick={handleClick}>
                            {item.name}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
