import React from 'react'
import {connect} from 'react-redux'
import {deleteThisListing} from "../../actions/listingsActions";
import DeleteContext from "./DeleteContext"

const DeleteListing = ({loading, hasErrors, closeModal, deleteConfirm, listingDeleted}) => {
    return (
        <DeleteContext.Consumer>
            {listingID => (
                <div>
                    <div className="header-toolbox">
                        <h1>Delete this listing?</h1>
                        <button className="btn btn-red" onClick={() => {
                            closeModal(false)
                        }}>x
                        </button>
                    </div>
                    <div className="bordered-container">
                        <p className="error-message">Are you sure to want to delete listing with id
                            <strong> {listingID}</strong>?
                        </p>
                        <button className="btn btn-red mr-2" onClick={() => {
                            deleteConfirm(listingID)
                        }}>Yes
                        </button>
                        <button className="btn btn-green" onClick={() => {
                            closeModal(false)
                        }}>No
                        </button>
                    </div>
                    {listingDeleted
                        ? closeModal(true)
                        : null
                    }
                </div>
            )}
        </DeleteContext.Consumer>
    )
};

const mapDispatchToProps = (dispatch) => {
    return {
        deleteConfirm: (listingId) => dispatch(deleteThisListing(listingId))
    }
};

const mapStateToProps = state => ({
    loading: state.deleteListing.loading,
    hasErrors: state.deleteListing.hasErrors,
    listingDeleted: state.deleteListing.listingDeleted,

});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteListing)

