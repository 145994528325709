import React from 'react'
import {Link} from 'react-router-dom'

export const ListingsListItem = ({listing, showDeleteModal}) => (
  <tr>
      <td style={{minWidth: "110px"}}><Link to={`/listing/${listing.listing_id}&days_stay=1`}>{listing.listing_id}</Link></td>
      <td style={{minWidth: "180px"}}>{listing.listing_nick}</td>
      <td>{listing.listing_name}</td>
      <td><a href={listing.listing_ical} target="_blank"> {listing.listing_ical} </a></td>
      <td><button className="btn btn-red" onClick={() => showDeleteModal(listing.listing_id)}>x</button></td>
  </tr>
);
