import React, {useEffect, useState} from 'react'
import { connect } from 'react-redux'
import { fetchListingsGroups } from "../../actions/listingsActions";
import HeaderBlock from "../../сomponents/Header";
import PreloaderBlock from "../../сomponents/Preloader"
import { NavLink } from 'react-router-dom'
import CreateListingGroup from "./CreateListingGroup"
import DeleteContext from "./DeleteContext";
import DeleteListingGroup from "./DeleteListingGroup";



const ListingsGroupsList = ({dispatch, loading, listingsGroups, hasErrors}) => {
    const [showCreateModal, setCreateModal] = useState(false);
    const [groupToDelete, setGroupToDelete] = useState(false);
    const [showDeleteModal, setDeleteModal] = useState(false);
    useEffect(() => {
        dispatch(fetchListingsGroups())
    }, [dispatch]);
    const handleOpenDeleteModal = (groupName) => {
        setGroupToDelete(groupName)
        setDeleteModal(true)
    };
    const handleDeleteModal = () => {
        dispatch(fetchListingsGroups())
        setDeleteModal(false)
    }
    const renderListingsGroups = () => {
        if (loading) return (
            <PreloaderBlock/>
        );
        if (hasErrors) return <p>error</p>;
        if (showDeleteModal && !loading && !hasErrors) {
            return (
                <DeleteContext.Provider value={groupToDelete}>
                    <DeleteListingGroup isDeleted={handleDeleteModal} closeModal={() => setDeleteModal(false)}/>
                </DeleteContext.Provider>
            )
        }
        if (showCreateModal && listingsGroups) {
            return <CreateListingGroup closeModal={() => setCreateModal(false)}/>
        }
        if (!showCreateModal && listingsGroups) {
            return (
                <div>
                    <div className="header-toolbox">
                        <h1>Listings groups</h1>
                        <div>
                            <button className="btn btn-green" onClick={() => setCreateModal(true)}>Add group</button>
                        </div>
                    </div>
                    <div className="bordered-container">
                        <table className="calendars-list-table">
                            <thead>
                            <tr>
                                <th>Name</th>
                                <th>Listings</th>
                                <th style={{textAlign: 'center'}}>Delete</th>
                            </tr>
                            </thead>
                            <tbody>
                            {listingsGroups.map(group => {
                                return (
                                    <tr key={group.groupId}>
                                        <td><NavLink to={`/listing/${group.groupId}`}>{group.groupName}</NavLink></td>
                                        <td>
                                            {group.groupListings.map((item, index) => {
                                                return (
                                                    <span key={index}>{item.listing_nick} ({item.listing_id})
                                                        {group.groupListings.length > index + 1
                                                            ? <span>, </span>
                                                            : null
                                                        }
                                              </span>
                                                )
                                            })}
                                        </td>
                                        <td style={{textAlign: 'center'}}>
                                            <button className="btn btn-red" onClick={() => handleOpenDeleteModal(group.groupId)}>X</button>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>
            )
        }

    };
    return (
        <div>
            <HeaderBlock/>
            <section className="main-section">
                {renderListingsGroups()}
            </section>
        </div>
    )
};

const mapStateToProps = state => ({
  loading: state.listingsGroups.loading,
  listingsGroups: state.listingsGroups.listingsGroups,
  hasErrors: state.listingsGroups.hasErrors,
});

export default connect(mapStateToProps)(ListingsGroupsList)
