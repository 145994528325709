import React, {useEffect, useState} from 'react'
import { connect } from 'react-redux'
import { fetchListingsCount, updateListingsCount } from "../../actions/listingsActions";


const ListingsCount = ({getListingsCount, loading, listingsCount, hasErrors, closeModal, sendForm}) => {
    const [count, setCount] = useState(0);
    useEffect(() => {
        getListingsCount()
    }, [getListingsCount]);

    useEffect(() => {
        if (listingsCount) {
            setCount(listingsCount.setting.setting_value.count)
        }
    }, [listingsCount]);

    const handleChange = (evt) => {
        setCount(evt.target.value)
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        sendForm(count);
        closeModal(false)
    };

    const renderListingsCountModal = () => {
        if (loading) return null;
        if (listingsCount && !loading && !hasErrors) {
            return (
                <div>
                    <div className="header-toolbox">
                        <h1>Update count</h1>
                        <button className="btn btn-red"  onClick={() => {
                            closeModal(false)
                        }}>x</button>
                    </div>
                    <div className="bordered-container">
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label>Listings count</label>
                                <input type="text" className="form-control" onChange={ handleChange }
                                       value={count} />
                            </div>
                            <button type="submit" className="btn btn-green">Update</button>
                        </form>
                    </div>
                </div>
            )
        }
        else return <p>error</p>;
    };
    return (
        <>
            {renderListingsCountModal()}
        </>
    )
};

const mapDispatchToProps = (dispatch) => {
    return {
        getListingsCount: () => dispatch(fetchListingsCount()),
        sendForm: (count) => dispatch(updateListingsCount(count))
    }
};

const mapStateToProps = state => ({
  loading: state.listingsCount.loading,
  listingsCount: state.listingsCount.listingsCount,
  hasErrors: state.listingsCount.hasErrors,
});

export default connect(mapStateToProps, mapDispatchToProps)(ListingsCount)

