import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { fetchListings } from "../../actions/listingsActions";
import { ListingsListItem } from './ListingsListItem'
import HeaderBlock from "../../сomponents/Header";
import PreloaderBlock from "../../сomponents/Preloader"
import ListingsCount from "./ListingsCount"
import CreateListing from "./CreateListing"
import DeleteListing from "./DeleteListing"
import DeleteContext from "./DeleteContext"



const ListingsList = ({dispatch, loading, listings, hasErrors}) => {
    const [showCountModal, setCountModal] = useState(false);
    const [showCreateModal, setCreateModal] = useState(false);
    const [showDeleteModal, setDeleteModal] = useState(false);
    const [listingToDelete, setListingToDelete] = useState(null);


    useEffect(() => {
        dispatch(fetchListings())
    }, [dispatch]);

    const handleNewListingCreated = () => {
        dispatch(fetchListings());
        setCreateModal(false)
    };

    const handleDeleteModal = (id) => {
        setDeleteModal(true);
        setListingToDelete(id)
    };

    const handleListingDeleted = () => {
        dispatch(fetchListings());
        setDeleteModal(false)
    };

    const renderListings = () => {
        if (loading) return (
            <PreloaderBlock closeModal={() => setCountModal(false)}/>
        );
        if (showDeleteModal && !loading && !hasErrors) {
            return (
                <DeleteContext.Provider value={listingToDelete}>
                    <DeleteListing closeModal={handleListingDeleted}/>
                </DeleteContext.Provider>
            )
        }
        if (!showCountModal && !showCreateModal && !loading && !hasErrors) {
            return (
                <div>
                    <div className="header-toolbox">
                        <h1>My listings</h1>
                        <div>
                            <button className="btn btn-yellow mr-2" onClick={() => setCountModal(true)}>Update count</button>
                            <button className="btn btn-green" onClick={() => setCreateModal(true)}>Add new</button>
                        </div>
                    </div>
                    <div className="bordered-container">
                        <table className="calendars-list-table">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Nick</th>
                                    <th>Name</th>
                                    <th>iCal</th>
                                    <th>Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                            {listings.map(listing => {
                                return (
                                    <ListingsListItem key={listing.listing_id} listing={listing}
                                                      showDeleteModal={() => handleDeleteModal(listing.listing_id)} />
                                )
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>
            )
        }
        if (showCountModal && !loading && !hasErrors) {
            return (
                <ListingsCount closeModal={() => setCountModal(false)}/>
            )
        }
        if (showCreateModal && !loading && !hasErrors) {
            return (
                <CreateListing closeModal={handleNewListingCreated}/>
            )
        }
        else return <p>error</p>;
    };
    return (
        <div>
            <HeaderBlock/>
            <section className="main-section">
                {renderListings()}
            </section>
        </div>
    )
};

const mapStateToProps = state => ({
  loading: state.listings.loading,
  listings: state.listings.listings,
  hasErrors: state.listings.hasErrors,
});

export default connect(mapStateToProps)(ListingsList)
