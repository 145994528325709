import axiosConfig from "../utils/api";

export const GET_SETTINGS = 'GET_SETTINGS';
export const GET_SETTINGS_SUCCESS = 'GET_SETTINGS_SUCCESS';
export const GET_SETTINGS_FAILURE = 'GET_SETTINGS_FAILURE';

export const GET_SETTINGS_CARD = 'GET_SETTINGS_CARD';
export const GET_SETTINGS_CARD_SUCCESS = 'GET_SETTINGS_CARD_SUCCESS';
export const GET_SETTINGS_CARD_FAILURE = 'GET_SETTINGS_CARD_FAILURE';

export const UPDATE_SETTINGS_CARD = 'UPDATE_SETTINGS_CARD';
export const UPDATE_SETTINGS_CARD_SUCCESS = 'UPDATE_SETTINGS_CARD_SUCCESS';
export const UPDATE_SETTINGS_CARD_FAILURE = 'UPDATE_SETTINGS_CARD_FAILURE';


export const getSettings = () => ({
    type: GET_SETTINGS
});

export const getSettingsSuccess = tasks => ({
    type: GET_SETTINGS_SUCCESS,
    payload: tasks
});

export const getSettingsFailure = () => ({
    type: GET_SETTINGS_FAILURE
});

export function fetchSettings() {
    return async dispatch => {
        dispatch(getSettings());
        try {
            const response = await axiosConfig.get('getAllScheduledTasks');
            const data = await response.data;
            dispatch(getSettingsSuccess(data))
        } catch (error) {
            dispatch(getSettingsFailure())
        }
    }
}


export const getSettingsCard = () => ({
    type: GET_SETTINGS_CARD
});

export const getSettingsCardSuccess = currentTask => ({
    type: GET_SETTINGS_CARD_SUCCESS,
    payload: currentTask
});

export const getSettingsCardFailure = () => ({
    type: GET_SETTINGS_CARD_FAILURE
});

export const updateSettings = () => ({
    type: UPDATE_SETTINGS_CARD
});

export const updateSettingsSuccess = () => ({
    type: UPDATE_SETTINGS_CARD_SUCCESS
});

export function fetchCurrentTaskSettings(taskId) {
    return async dispatch => {
        dispatch(getSettingsCard());
        try {
            const response = await axiosConfig.get(`getCurrentScheduledTask?task_id=${taskId}`);
            const data = await response.data;
            dispatch(getSettingsCardSuccess(data))
        } catch (error) {
            dispatch(getSettingsCardFailure())
        }
    }
}

export function updateCurrentTaskSettings(taskData) {
    return async dispatch => {
        dispatch(updateSettings());
        try {
            const response = await axiosConfig.post('updateCurrentScheduledTask', taskData);
            const data = await response.data;
            dispatch(updateSettingsSuccess());
            window.location.replace('/scheduler')
            // window.scrollTo({top: 0, behavior: "smooth"});
        } catch (error) {
            dispatch(getSettingsCardFailure())
        }
    }
}

