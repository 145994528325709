import axiosConfig from '../utils/api'

export const GET_TASKS = 'GET_TASKS';
export const GET_TASKS_SUCCESS = 'GET_TASKS_SUCCESS';
export const GET_TASKS_FAILURE = 'GET_TASKS_FAILURE';

export const getTasks = () => ({
    type: GET_TASKS
});

export const getTasksSuccess = tasks => ({
    type: GET_TASKS_SUCCESS,
    payload: tasks
});

export const getTasksFailure = () => ({
    type: GET_TASKS_FAILURE
});

export function fetchTasks() {
    return async dispatch => {
        dispatch(getTasks());
        try {
            const response = await axiosConfig.get('getClientTasks');
            const data = await response.data;
            dispatch(getTasksSuccess(data))
        } catch (error) {
            dispatch(getTasksFailure())
        }
    }
}
