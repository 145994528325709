import React, {useEffect, useState, useRef, useCallback} from 'react'
import {connect} from 'react-redux'
import HeaderBlock from "../../сomponents/Header";
import axiosConfig from "../../utils/api";
import { Board } from "./Board";

const ListingsGroupsSMS = () => {
    const [board, setBoard] = useState([]);

    const getListingsList = () => {
        axiosConfig.get('getSMSListingGroups').then(res => {
            setBoard(res.data.groups_data)
        })
    }

    const saveGroups = () => {
        axiosConfig.post('saveSMSListingGroups', {groups_data: board}).then(res => {
            console.log(res)
            window.location.reload()
        })
    }

    useEffect(() => {
        getListingsList()
    }, [])


    const addNewGroup = () => {
        const elem = {
            id: Math.floor(Math.random() * 999999) + 5,
            name: "Empty name",
            items: [],
        }
        setBoard([...board, elem])
    }

    const handleColumnNameChanged = e => {
        const newBoard = board
        newBoard[e.index].name = e.value
        setBoard(board)
    }

    return (
        <>
            <HeaderBlock/>
            <section className="main-section">
                <div className="header-toolbox">
                    <h1>SMS Listings groups</h1>
                    <button className='btn btn-green' onClick={() => addNewGroup()}>add new group</button>
                </div>
                <div className="bordered-container">

                    <div className=''>
                        <Board columnItems={board} onColumnItemsChange={setBoard}
                        onHandleColumnNameChange={e => handleColumnNameChanged(e)}
                        />
                    </div>

                    <button className='btn btn-yellow' style={{marginTop: 20}}
                            onClick={() => saveGroups()}>save groups</button>

                </div>
            </section>
        </>
    )
}

export default connect()(ListingsGroupsSMS)
