import React from 'react'
import moment from 'moment'

export const ProxyListItem = ({proxy}) => {
    return (
        <tr>
            <td>
                {moment(proxy.date_end).utcOffset(7).format('MM/D/YYYY')}
                <span className="text-green"> ({moment(Date.now()).diff(proxy.date_end, 'days')} days)</span>
            </td>
            <td>{proxy.proxy}</td>
        </tr>
    )
};
