import React, {useEffect, useState} from 'react'
import axiosConfig from "../../utils/api";
import {connect} from 'react-redux'
import HeaderBlock from "../../сomponents/Header";


const AdminSettings = ({dispatch, loading, tasks, hasErrors}) => {
    const [phonesList, setPhonesList] = useState([]);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [showCreateModal, setShowCreateModal] = useState(false)

    const getPhones = () => {
        setPhonesList([])
        axiosConfig.get('getPhones').then(r => {
            setPhonesList(r.data)
        })
    }

    useEffect(() => {
        getPhones()
    }, [])

    const handleChange = (evt) => {
        setPhoneNumber(evt.target.value)
    };

    const createPhone = (e) => {
        e.preventDefault()
        console.log(phoneNumber)
        let body = {
            "phoneNumber": phoneNumber
        }
        axiosConfig.post(`createPhone`, body).then(r => {
            console.log(r)
            setShowCreateModal(false)
            getPhones()
        })
    }
    const deletePhone = (uid) => {
        axiosConfig.delete(`deletePhone?uid=${uid}`).then(r => {
            console.log(r)
            getPhones()
        })
    }

    return (
        <div>
            <HeaderBlock/>
            <section className="main-section">
                <div className="header-toolbox">
                    <h1>Settings</h1>
                </div>

                {showCreateModal ?
                    <div className="bordered-container">
                        <form onSubmit={createPhone}>
                            <div className="form-group">
                                <label>Phone Number</label>
                                <input type="text" className="form-control" required
                                       name="listing_id" onChange={handleChange}/>
                            </div>
                            {loading
                                ? <button type="submit" className="btn btn-green disabled-btn">Creating...</button>
                                : <button type="submit" className="btn btn-green">Create</button>
                            }
                            <button type="submit" className="btn btn-gray"
                                    style={{marginLeft: 20}} onClick={() => setShowCreateModal(false)}>
                                Close
                            </button>
                        </form>
                    </div>
                    :
                    <div className="bordered-container" style={{paddingRight: 20, paddingLeft: 20}}>
                        <div className="header-toolbox">
                            <h3 style={{lineHeight: 0}}>Phones List</h3>
                            <button className="btn btn-green" onClick={() => setShowCreateModal(true)}>
                                Add Phone
                            </button>
                        </div>
                        {phonesList.length ?
                            <table className="calendars-list-table">
                                <thead>
                                <tr>
                                    <th>Phone</th>
                                    <th style={{textAlign: 'right'}}>Delete</th>
                                </tr>
                                </thead>
                                <tbody>
                                {phonesList.map(phone => {
                                    return (
                                        <tr key={phone.uid}>
                                            <td>
                                                {phone.phone_number}
                                            </td>
                                            <td style={{textAlign: 'right'}}>
                                                <button className="btn btn-red"
                                                        onClick={() => deletePhone(phone.uid)}>x
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                })}

                                </tbody>
                            </table>
                            : <p>No phones, please add</p>
                        }
                    </div>
                }

            </section>
        </div>
    )
};

const mapStateToProps = state => ({
    loading: state.tasks.loading,
    tasks: state.tasks.tasks,
    hasErrors: state.tasks.hasErrors,
});

export default connect(mapStateToProps)(AdminSettings)
