import axiosConfig from "../utils/api";

export const GET_PROXIES = 'GET_PROXIES';
export const CHECK_PROXIES = 'CHECK_PROXIES';
export const GET_PROXIES_SUCCESS = 'GET_PROXIES_SUCCESS';
export const CHECK_PROXIES_SUCCESS = 'CHECK_PROXIES_SUCCESS';
export const GET_PROXIES_FAILURE = 'GET_PROXIES_FAILURE';
export const CHECK_PROXIES_FAILURE = 'CHECK_PROXIES_FAILURE';

export const getProxies = () => ({
    type: GET_PROXIES
});

export const checkProxies = () => ({
    type: CHECK_PROXIES
});

export const getProxiesSuccess = proxies => ({
    type: GET_PROXIES_SUCCESS,
    payload: proxies
});

export const checkProxiesSuccess = checkedProxies => ({
    type: CHECK_PROXIES_SUCCESS,
    payload: checkedProxies
});

export const getProxiesFailure = () => ({
    type: GET_PROXIES_FAILURE
});

export const checkProxiesFailure = () => ({
    type: CHECK_PROXIES_FAILURE
});

export function fetchProxies() {
    return async dispatch => {
        dispatch(getProxies());
        try {
            const response = await axiosConfig.get('getProxiesList');
            const data = await response.data;
            dispatch(getProxiesSuccess(data))
        } catch (error) {
            dispatch(getProxiesFailure())
        }
    }
}

export function fetchCheckedProxies() {
    return async dispatch => {
        dispatch(checkProxies());
        try {
            const response = await axiosConfig.get('checkProxies');
            const data = await response.data;
            dispatch(checkProxiesSuccess(data))
        } catch (error) {
            dispatch(checkProxiesFailure())
        }
    }
}
