import React from 'react'
import { NavLink } from "react-router-dom";

export const SchedulerListItem = ({ setting }) => {
    return (
        <tr>
            <td><NavLink to={`/scheduler/${setting.task_id}`}>{setting.task_id}</NavLink></td>
            <td>{setting.task_params.query}</td>
            <td>{setting.task_params.days_stay}</td>
            <td>{setting.task_params.adults_count}</td>
            <td>{setting.task_params.pets_count}</td>
        </tr>
    )
};
