import * as actions from '../actions/tasksActions'

export const initialState = {
  tasks: [],
  loading: false,
  hasErrors: false,
};

export default function tasksReducer(state = initialState, action) {
  switch (action.type) {
      case actions.GET_TASKS:
          return {...state, loading: true};
      case actions.GET_TASKS_SUCCESS:
        return {tasks: action.payload, loading: false, hasErrors: false};
      case actions.GET_TASKS_FAILURE:
        return {...state, loading: false, hasErrors: true};
    default:
      return state
  }
}
