import axios from "axios";

let url = "http://0.0.0.0:5006/api/";
if (process.env.NODE_ENV !== "development") {
  url = 'https://api.rohanservices.com/api/'
}

const axiosConfig = axios.create({
    baseURL: url,
    responseType: "json"
});

export default axiosConfig;
