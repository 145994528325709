import React, {useEffect, useState} from 'react'
import { connect } from 'react-redux'
import {createNewListingGroup, getListingsWithoutGroups} from "../../actions/listingsActions";


const CreateListingGroup = ({loading, hasErrors, closeModal, sendForm,
                                getListings, listingsWithoutGroups, listingGroupCreated}) => {
    const [listingData, setListingData] = useState({
        groupName: null,
        groupListings: []
    });

    const [newGroupError, setError] = useState(null);

    useEffect(() => {
        getListings()
    }, [getListings]);

    const handleChange = (evt) => {
        listingData[evt.target.name] = evt.target.value;
        setListingData({
            ...listingData,
        })
    };

    const addListingToGroup = (listing) => {
        let groupName = listingData.groupName;
        setListingData({groupListings: [...listingData.groupListings, listing], groupName: groupName})
    };

    const removeListingFromGroup = (listing) => {
        let groupName = listingData.groupName;
        setListingData({
            groupListings: listingData.groupListings.filter(obj => (obj.listing_id !== listing.listing_id)),
            groupName: groupName
        })
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setError(null);
        if (!listingData.groupListings.length) {
            setError(<p className="error-message">New group cant be blank</p>)
        } else {
            sendForm(listingData)
        }
    };

    if (listingsWithoutGroups) {
        return (
            <div>
                <div className="header-toolbox">
                    <h1>Create new group</h1>
                    <button className="btn btn-red" onClick={() => {
                        closeModal(false)
                    }}>x
                    </button>
                </div>
                <div className="bordered-container">
                    <form onSubmit={handleSubmit}>

                        <div className="form-group">
                            <label>Group name</label>
                            <input type="text" className="form-control" required
                                   name="groupName" onChange={handleChange}/>
                        </div>

                        <div className="groups-wrapper">

                            <div className='sort-rows'>
                                <h3>Listings</h3>
                                <div className="no-group-listings sort-listings">
                                    <ul>
                                        {listingsWithoutGroups.map(listing => {
                                            return (
                                                <li key={listing.listing_nick}
                                                    onClick={() => addListingToGroup(listing)}>{listing.listing_nick}</li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </div>

                            <div className='sort-rows'>
                                <h3  style={{marginLeft: '10px'}}>New group listings</h3>
                                <div className="new-group-listings sort-listings">
                                    <ul>
                                        {listingData.groupListings.map(listing => {
                                            return (
                                                <li key={listing.listing_nick}
                                                    onClick={() => removeListingFromGroup(listing)}>{listing.listing_nick}</li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </div>

                        </div>

                        {loading
                            ? <button type="submit" className="btn btn-green disabled-btn">Creating...</button>
                            : <button type="submit" className="btn btn-green">Create</button>
                        }
                        {newGroupError}

                        {listingGroupCreated
                            ? closeModal(false)
                            : null
                        }

                    </form>
                </div>
            </div>
        )
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getListings: () => dispatch(getListingsWithoutGroups()),
        sendForm: (groupData) => dispatch(createNewListingGroup(groupData))
    }
};

const mapStateToProps = state => ({
    loading: state.listingsWithoutGroups.loading,
    hasErrors: state.listingsWithoutGroups.hasErrors,
    listingsWithoutGroups: state.listingsWithoutGroups.listingsWithoutGroups,
    listingGroupCreated: state.createListingGroup.listingGroupCreated
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateListingGroup)

