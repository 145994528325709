import * as actions from '../actions/schedulerActions'

export const initialState = {
  currentTask: null,
  loading: false,
  hasErrors: false
};

export default function currentTaskReducer(state = initialState, action) {
  switch (action.type) {
      case actions.GET_SETTINGS_CARD:
          return {...state, loading: true};
      case actions.GET_SETTINGS_CARD_SUCCESS:
        return {currentTask: action.payload, loading: false, hasErrors: false};
      case actions.GET_SETTINGS_CARD_FAILURE:
        return {...state, loading: false, hasErrors: true};
      case actions.UPDATE_SETTINGS_CARD:
        return {...state, loading: true};
      case actions.UPDATE_SETTINGS_CARD_SUCCESS:
        return {...state, loading: false};
    default:
      return state
  }
}
