import * as actions from '../actions/calendarActions'

export const initialState = {
  calendarCard: null,
  loading: false,
  hasErrors: false,
  cardIndex: null,
  listingType: 0
};

export default function calendarCardReducer(state = initialState, action) {
  switch (action.type) {
      case actions.CHANGE_CARD_INDEX:
          return {...state, cardIndex: action.newIndex};
      case actions.CHANGE_LISTING_TYPE:
          return {...state, listingType: action.listingType};
      case actions.GET_CALENDAR_CARD:
          return {...state, loading: true};
      case actions.GET_CALENDAR_CARD_SUCCESS:
        return {calendarCard: action.payload, loading: false, hasErrors: false};
      case actions.GET_CALENDAR_CARD_FAILURE:
        return {...state, loading: false, hasErrors: true};
    default:
      return state
  }
}
