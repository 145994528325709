import React from 'react'
import moment from 'moment'

export const TasksListItem = ({task}) => {
    let doneStatus;
    if (task.done_status) {
        doneStatus = <span className="done-task">done</span>
    } else {
        doneStatus = <span className="pending-task">pending</span>
    }
    return (
        <tr>
            <td>{task.task_name}</td>
            <td>{moment(task.created).utcOffset(7).format('MM/D/YYYY – HH:mm')}</td>
            <td>{doneStatus}</td>
            <td>{task.execution_time} min.</td>
        </tr>
    )
};
