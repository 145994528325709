import React, {useState} from 'react'
import {connect} from 'react-redux'
import {createNewListing} from "../../actions/listingsActions";


const CreateListing = ({loading, hasErrors, closeModal, sendForm, listingCreated}) => {
    const [listingData, setListingData] = useState({
        listing_ical: null,
        listing_id: null,
        listing_name: null,
        listing_nick: null
    });

    const handleChange = (evt) => {
        listingData[evt.target.name] = evt.target.value;
        setListingData({
            ...listingData,
        })
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        sendForm(listingData);
    };

    return (
        <div>
            <div className="header-toolbox">
                <h1>Create new listing</h1>
                <button className="btn btn-red" onClick={() => {
                    closeModal(false)
                }}>x
                </button>
            </div>
            <div className="bordered-container">
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>Listings ID</label>
                        <input type="text" className="form-control" required
                               name="listing_id" onChange={handleChange}/>
                    </div>
                    <div className="form-group">
                        <label>Listings name</label>
                        <input type="text" className="form-control" required
                               name="listing_name" onChange={handleChange}/>
                    </div>
                    <div className="form-group">
                        <label>Listings nick</label>
                        <input type="text" className="form-control" required
                               name="listing_nick" onChange={handleChange}/>
                    </div>
                    <div className="form-group">
                        <label>Listings iCal</label>
                        <input type="text" className="form-control" required
                               name="listing_ical" onChange={handleChange}/>
                    </div>

                    {loading
                        ? <button type="submit" className="btn btn-green disabled-btn">Creating...</button>
                        : <button type="submit" className="btn btn-green">Create</button>
                    }
                    {listingCreated
                        ? closeModal(false)
                        : null
                    }
                </form>
            </div>
        </div>
    )
};

const mapDispatchToProps = (dispatch) => {
    return {
        sendForm: (listingData) => dispatch(createNewListing(listingData))
    }
};

const mapStateToProps = state => ({
    loading: state.createListing.loading,
    hasErrors: state.createListing.hasErrors,
    listingCreated: state.createListing.listingCreated,

});

export default connect(mapStateToProps, mapDispatchToProps)(CreateListing)

