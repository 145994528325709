import React, {useEffect, useState} from 'react'
import { connect } from 'react-redux'
import { fetchCalendars } from "../../actions/calendarActions";
import { CalendarsListItem } from './CalendarsListItem'
import HeaderBlock from "../../сomponents/Header";
import PreloaderBlock from "../../сomponents/Preloader"
import CreateCalendar from './CreateCalendar'
import CalendarsFilter from './CalendarsFilter'
import moment from "moment";


const CalendarsList = ({dispatch, loading, calendars, hasErrors}) => {
    const now = new Date();
    const [dataLimit, setDataLimit] = useState(25);
    const [strDates, setStrDates] = useState(null);
    const [calendarsLen, setCalendarsLen] = useState(0);
    const [showLoadMore, setShowLoadMore] = useState(true);
    const [firstLoad, setFirstLoad] = useState(true);

    const [showCreateModal, setCreateModal] = useState(false);
    const [isCreated, setCreated] = useState(false);
    const [filteredData, setFilter] = useState(null);
    const [dates, setDates] = useState([moment(now).utcOffset(7).format('MM/DD/YYYY'), moment(now).utcOffset(7).format('MM/DD/YYYY')]);

    const datesHandler = () => {
        if (localStorage.getItem('dates') === null) {
            const now = new Date();
            setDates([moment(now).utcOffset(7).format('MM/DD/YYYY'), moment(now).utcOffset(7).format('MM/DD/YYYY')]);
        } else {
            const lsDates = JSON.parse(localStorage.getItem('dates'));
            setDates([moment(lsDates[0]).utcOffset(7).format('MM/DD/YYYY'), moment(lsDates[1]).utcOffset(7).format('MM/DD/YYYY')]);
        }
    };

    useEffect(() => {
        dispatch(fetchCalendars(null, dataLimit));
    }, [dispatch]);

    useEffect(() => {
        if(calendars) {
            setFilter(calendars.calendars_list);
        }
    }, [calendars]);

    const handleCalendarFiltration = (event) => {
        let val = event.target.value;
        if (val === 'all groups') {
            setFilter(calendars.calendars_list)
        } else {
            let filtered = calendars.calendars_list.filter(obj => (obj.group === val));
            setFilter(filtered)
        }

    };

    const loadMoreCalendars = () => {
        setDataLimit(dataLimit + 25);
        setFirstLoad(false)

    };
    useEffect(() => {
        if (dataLimit > 25) {
            dispatch(fetchCalendars(strDates, dataLimit));
        }
        if(calendars.calendars_list) {
            console.log({'first': firstLoad, 'calendarsLen': calendarsLen, 'calendars.calendars_list.length':calendars.calendars_list.length})
            if (!firstLoad) {
                if (calendarsLen >= calendars.calendars_list.length) {
                    console.log('STOP LOAD');
                    setShowLoadMore(false)
                }
                setCalendarsLen(calendars.calendars_list.length)
            }
        }
    }, [dataLimit]);

    const handleDatesChanged = (event) => {
        setFirstLoad(true);
        setStrDates(event);
        setShowLoadMore(true);
        setDataLimit(25);
        setCalendarsLen(0);
        dispatch(fetchCalendars(event)).then(() => {
            datesHandler();
        })
    };

    const handleNewCalendarCreated = () => {
        setCreateModal(false);
        setCreated(true);
        setTimeout(() => {
            setCreated(false)
        }, 3000)
    };

    let createdBlock = null;
    if (isCreated) {
        createdBlock = <p className="text-green">New calendar task created</p>
    }

    const renderCalendars = () => {
        if (loading) return (
            <PreloaderBlock/>
        );
        if (hasErrors) return <p>error</p>;
        if (calendars && showCreateModal && !loading && !hasErrors) {
            return (<CreateCalendar closeModal={handleNewCalendarCreated}/>)
        }
        if (filteredData && !showCreateModal && !loading && !hasErrors) {
            return (
                <div>
                    <div className="header-toolbox">
                        <h1>Calendars List</h1>
                        <button className="btn btn-green" onClick={() => setCreateModal(true)}>Create</button>
                    </div>
                    <p>{createdBlock}</p>
                    <div className="bordered-container">
                        <CalendarsFilter calendars={calendars} datesChanged={handleDatesChanged} dates={dates}
                                         doFiltration={handleCalendarFiltration}/>

                        <table className="calendars-list-table">
                            <thead>
                            <tr>
                                <th>Name</th>
                                <th>Query</th>
                                <th>Days</th>
                                <th>Guests</th>
                                <th>Pets allowed</th>
                                <th>Scraping Date</th>
                            </tr>
                            </thead>
                            <tbody>
                            {filteredData.map(calendar => {
                                return (
                                    <CalendarsListItem key={calendar.calendar_id} calendar={calendar}/>
                                )
                            })}
                            </tbody>
                        </table>

                        {showLoadMore && <button className="btn btn-green load-more-btn" onClick={loadMoreCalendars}>Load More</button> }

                    </div>
                </div>
            )
        }

    };
    return (
        <div>
            <HeaderBlock/>
            <section className="main-section">
                {renderCalendars()}
            </section>
        </div>
    )
};

const mapStateToProps = state => ({
  loading: state.calendars.loading,
  calendars: state.calendars.calendars,
  hasErrors: state.calendars.hasErrors,
});

export default connect(mapStateToProps)(CalendarsList)
