import React from 'react'
import {connect} from 'react-redux'
import {deleteThisListingGroup} from "../../actions/listingsActions";
import DeleteContext from "./DeleteContext"

const DeleteListingGroup = ({loading, hasErrors, closeModal, isDeleted , deleteConfirm, listingGroupDeleted}) => {
    return (
        <DeleteContext.Consumer>
            {groupID => (
                <div>
                    <div className="header-toolbox">
                        <h1>Delete this listing?</h1>
                        <button className="btn btn-red" onClick={() => {
                            closeModal(false)
                        }}>x
                        </button>
                    </div>
                    <div className="bordered-container">
                        <p className="error-message">Are you sure to want to delete this group
                            <strong> {groupID}</strong>?
                        </p>
                        <button className="btn btn-red mr-2" onClick={() => {
                            deleteConfirm(groupID)
                        }}>Yes
                        </button>
                        <button className="btn btn-green" onClick={() => {
                            closeModal(false)
                        }}>No
                        </button>
                    </div>
                    {listingGroupDeleted
                        ? isDeleted(true)
                        : null
                    }
                </div>
            )}
        </DeleteContext.Consumer>
    )
};

const mapDispatchToProps = (dispatch) => {
    return {
        deleteConfirm: (listingId) => dispatch(deleteThisListingGroup(listingId))
    }
};

const mapStateToProps = state => ({
    loading: state.deleteListingGroup.loading,
    hasErrors: state.deleteListingGroup.hasErrors,
    listingGroupDeleted: state.deleteListingGroup.listingGroupDeleted,

});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteListingGroup)

