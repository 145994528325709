import axiosConfig from "../utils/api";

export const GET_LISTINGS = 'GET_LISTINGS';
export const GET_LISTINGS_SUCCESS = 'GET_LISTINGS_SUCCESS';
export const GET_LISTINGS_FAILURE = 'GET_LISTINGS_FAILURE';

export const GET_LISTINGS_GROUPS = 'GET_LISTINGS_GROUPS';
export const GET_LISTINGS_GROUPS_SUCCESS = 'GET_LISTINGS_GROUPS_SUCCESS';
export const GET_LISTINGS_GROUPS_FAILURE = 'GET_LISTINGS_GROUPS_FAILURE';

export const GET_LISTINGS_COUNT = 'GET_LISTINGS_COUNT';
export const GET_LISTINGS_COUNT_SUCCESS = 'GET_LISTINGS_COUNT_SUCCESS';
export const GET_LISTINGS_COUNT_FAILURE = 'GET_LISTINGS_COUNT_FAILURE';

export const UPDATE_COUNT = 'UPDATE_COUNT';
export const UPDATE_COUNT_SUCCESS = 'UPDATE_COUNT_SUCCESS';

export const CREATE_LISTING = 'CREATE_LISTING';
export const CREATE_LISTING_SUCCESS = 'CREATE_LISTING_SUCCESS';
export const CREATE_LISTING_FAILURE = 'CREATE_LISTING_FAILURE';


export const getListings = () => ({
    type: GET_LISTINGS
});

export const getListingsSuccess = listings => ({
    type: GET_LISTINGS_SUCCESS,
    payload: listings
});

export const getListingsFailure = () => ({
    type: GET_LISTINGS_FAILURE
});

export function fetchListings() {
    return async dispatch => {
        dispatch(getListings());
        try {
            const response = await axiosConfig.get('getClientListings');
            const data = await response.data;
            dispatch(getListingsSuccess(data))
        } catch (error) {
            dispatch(getListingsFailure())
        }
    }
}

export const getListingsGroups = () => ({
    type: GET_LISTINGS_GROUPS
});

export const getListingsGroupsSuccess = listingsGroups => ({
    type: GET_LISTINGS_GROUPS_SUCCESS,
    payload: listingsGroups
});

export const getListingsGroupsFailure = () => ({
    type: GET_LISTINGS_GROUPS_FAILURE
});

export function fetchListingsGroups() {
    return async dispatch => {
        dispatch(getListingsGroups());
        try {
            const response = await axiosConfig.get('getClientListingsGroups');
            const data = await response.data;
            dispatch(getListingsGroupsSuccess(data))
        } catch (error) {
            dispatch(getListingsGroupsFailure())
        }
    }
}

export const getListingsCount = () => ({
    type: GET_LISTINGS_COUNT
});

export const getListingsCountSuccess = listingsCount => ({
    type: GET_LISTINGS_COUNT_SUCCESS,
    payload: listingsCount
});

export const getListingsCountFailure = () => ({
    type: GET_LISTINGS_COUNT_FAILURE
});

export function fetchListingsCount() {
    return async dispatch => {
        dispatch(getListingsCount());
        try {
            const response = await axiosConfig.get('findSettings?setting_id=listings_count');
            const data = await response.data;
            dispatch(getListingsCountSuccess(data))
        } catch (error) {
            dispatch(getListingsCountFailure())
        }
    }
}

export const updateCount = () => ({
    type: UPDATE_COUNT
});

export const updateCountSuccess = () => ({
    type: UPDATE_COUNT_SUCCESS
});

export function updateListingsCount(count) {
    return async dispatch => {
        dispatch(updateCount());
        try {
            const response = await axiosConfig.post('updateClientListingsCount',
                {listingsCount: count, settingId: "listings_count"});
            const data = await response.data;
            console.log(data);
            dispatch(updateCountSuccess());
        } catch (error) {
            dispatch(getListingsGroupsFailure())
        }
    }
}

export const createListing = () => ({
    type: CREATE_LISTING
});

export const createListingSuccess = () => ({
    type: CREATE_LISTING_SUCCESS
});

export const createListingFailure = () => ({
    type: CREATE_LISTING_FAILURE
});

export function createNewListing(listingData) {
    return async dispatch => {
        dispatch(createListing());
        try {
            const response = await axiosConfig.post('creteListing', listingData);
            const data = await response.data;
            console.log(data);
            dispatch(createListingSuccess());
        } catch (error) {
            dispatch(createListingFailure())
        }
    }
}

export const DELETE_LISTING = 'DELETE_LISTING';
export const DELETE_LISTING_SUCCESS = 'DELETE_LISTING_SUCCESS';
export const DELETE_LISTING_FAILURE = 'DELETE_LISTING_FAILURE';

export const deleteListing = () => ({
    type: DELETE_LISTING
});

export const deleteListingSuccess = () => ({
    type: DELETE_LISTING_SUCCESS
});

export const deleteListingFailure = () => ({
    type: DELETE_LISTING_FAILURE
});

export function deleteThisListing(listingId) {
    return async dispatch => {
        dispatch(deleteListing());
        try {
            const response = await axiosConfig.get(`delClientListings?listingId=${listingId}`);
            const data = await response.data;
            console.log(data);
            dispatch(deleteListingSuccess());
        } catch (error) {
            dispatch(deleteListingFailure())
        }
    }
}

export const GET_NO_GROUP_LISTINGS = 'GET_NO_GROUP_LISTINGS';
export const GET_NO_GROUP_LISTINGS_SUCCESS = 'GET_NO_GROUP_LISTINGS_SUCCESS';
export const GET_NO_GROUP_LISTINGS_FAILURE = 'GET_NO_GROUP_LISTINGS_FAILURE';

export const getNoGroupListings = () => ({
    type: GET_NO_GROUP_LISTINGS
});

export const getNoGroupListingsSuccess = listingsWithoutGroups => ({
    type: GET_NO_GROUP_LISTINGS_SUCCESS,
    payload: listingsWithoutGroups
});

export const getNoGroupListingsFailure = () => ({
    type: GET_NO_GROUP_LISTINGS_FAILURE
});

export function getListingsWithoutGroups() {
    return async dispatch => {
        dispatch(getNoGroupListings());
        try {
            const response = await axiosConfig.get('getClientListingsWithoutGroup');
            const data = await response.data;
            dispatch(getNoGroupListingsSuccess(data));
        } catch (error) {
            dispatch(getNoGroupListingsFailure())
        }
    }
}

export const CREATE_LISTING_GROUP = 'CREATE_LISTING_GROUP';
export const CREATE_LISTING_GROUP_SUCCESS = 'CREATE_LISTING_GROUP_SUCCESS';
export const CREATE_LISTING_GROUP_FAILURE = 'CREATE_LISTING_GROUP_FAILURE';

export const createListingGroup = () => ({
    type: CREATE_LISTING_GROUP
});

export const createListingGroupSuccess = () => ({
    type: CREATE_LISTING_GROUP_SUCCESS
});

export const createListingGroupFailure = () => ({
    type: CREATE_LISTING_GROUP_FAILURE
});

export function createNewListingGroup(groupData) {
    return async dispatch => {
        dispatch(createListingGroup());
        try {
            const response = await axiosConfig.post('createClientListingsGroup', groupData);
            const data = await response.data;
            console.log(data);
            dispatch(createListingGroupSuccess());
        } catch (error) {
            dispatch(createListingGroupFailure())
        }
    }
}

export const DELETE_LISTING_GROUP = 'DELETE_LISTING_GROUP';
export const DELETE_LISTING_GROUP_SUCCESS = 'DELETE_LISTING_GROUP_SUCCESS';
export const DELETE_LISTING_GROUP_FAILURE = 'DELETE_LISTING_GROUP_FAILURE';

export const deleteListingGroup = () => ({
    type: DELETE_LISTING_GROUP
});

export const deleteListingGroupSuccess = () => ({
    type: DELETE_LISTING_GROUP_SUCCESS
});

export const deleteListingGroupFailure = () => ({
    type: DELETE_LISTING_GROUP_FAILURE
});

export function deleteThisListingGroup(groupId) {
    return async dispatch => {
        dispatch(deleteListingGroup());
        try {
            const response = await axiosConfig.post('deleteClientListingsGroup', {groupId: groupId});
            const data = await response.data;
            console.log(data);
            dispatch(deleteListingGroupSuccess());
        } catch (error) {
            dispatch(deleteListingGroupFailure())
        }
    }
}

export const GET_LISTING_CHART_DATA = 'GET_LISTING_CHART_DATA';
export const GET_LISTING_CHART_DATA_SUCCESS = 'GET_LISTING_CHART_DATA_SUCCESS';
export const GET_LISTING_CHART_DATA_FAILURE = 'GET_LISTING_CHART_DATA_FAILURE';

export const getListingChartData = () => ({
    type: GET_LISTING_CHART_DATA
});

export const getListingChartDataSuccess = chartData => ({
    type: GET_LISTING_CHART_DATA_SUCCESS,
    payload: chartData
});

export const getListingChartDataFailure = () => ({
    type: GET_LISTING_CHART_DATA_FAILURE
});

export function fetchListingChartData(listingID, days=1) {
    return async dispatch => {
        dispatch(getListingChartData());
        try {
            const response = await axiosConfig.get(`getListingChartData?listing_id=${listingID}&days_stay=${days}`);
            const data = await response.data;
            dispatch(getListingChartDataSuccess(data))
        } catch (error) {
            dispatch(getListingChartDataFailure())
        }
    }
}
