import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'


const HeaderBlock = () => {
    const [showMenu, setMenu] = useState('');
    let menuBlock = null;
    if (showMenu) {
        menuBlock =
            <ul className="main-nav-list">
                        <li>
                            <NavLink to={'/'} exact activeClassName="active-link">Calendars</NavLink>
                        </li>
                        <li>
                            <NavLink to={'/listings'} activeClassName="active-link">Listings</NavLink>
                        </li>
                        <li>
                            <NavLink to={'/groups'} activeClassName="active-link">Listing groups</NavLink>
                        </li>
                        <li>
                            <NavLink to={'/scheduler'} activeClassName="active-link">Scheduler</NavLink>
                        </li>
                        <li>
                            <NavLink to={'/proxies'} activeClassName="active-link">Proxies</NavLink>
                        </li>
                        <li>
                            <NavLink to={'/tasks'} activeClassName="active-link">Tasks</NavLink>
                        </li>
                    </ul>
    }
    return(
        <header className="main-header">
            <div className="header-container">
                <h3 className="mh-logo"><NavLink to={'/'} className="mh-logo">Rohanservices</NavLink></h3>
                <nav className="main-nav">
                    <p className="menu-toggle" onClick={() => setMenu(!showMenu)}>Menu</p>
                    {menuBlock}
                    <ul className="main-nav-list desktop-nav">
                        <li>
                            <NavLink to={'/'} exact activeClassName="active-link">Calendars</NavLink>
                        </li>
                        <li>
                            <NavLink to={'/listings'} activeClassName="active-link">Listings</NavLink>
                        </li>
                        <li>
                            <NavLink to={'/groups'} activeClassName="active-link">Listing groups</NavLink>
                        </li>
                        <li>
                            <NavLink to={'/sms-groups'} activeClassName="active-link">SMS Listing groups</NavLink>
                        </li>
                        <li>
                            <NavLink to={'/scheduler'} activeClassName="active-link">Scheduler</NavLink>
                        </li>
                        <li>
                            <NavLink to={'/proxies'} activeClassName="active-link">Proxies</NavLink>
                        </li>
                        <li>
                            <NavLink to={'/tasks'} activeClassName="active-link">Tasks</NavLink>
                        </li>
                        <li>
                            <NavLink to={'/settings'} activeClassName="active-link">Settings</NavLink>
                        </li>
                    </ul>
                </nav>
            </div>
        </header>
    )
};

export default HeaderBlock;
