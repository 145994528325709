import styles from '../../assets/style.css'

import * as R from 'ramda'
import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {fetchCalendar, handleCalendarIndex, handleListingsTypes} from "../../actions/calendarActions";
import HeaderBlock from "../../сomponents/Header";

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

import moment from "moment";

const median = arr => {
    const mid = Math.floor(arr.length / 2),
        nums = [...arr].sort((a, b) => a - b);
    return arr.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2;
};
const countAvg = (prices, range) => {
    const slicedList = prices.slice(0, range);
    const listSum = slicedList.reduce((a, b) => a + b, 0);
    return ((listSum / slicedList.length) || 0).toFixed(1)
};
const countMedian = (prices, range) => {
    const slicedList = prices.slice(0, range)
    return median(slicedList).toFixed(1)
};
const getDayName = (a) => {
    return moment(a).utcOffset(7).format('ddd');
};
const handleDate = (a) => {
    a = moment(a).utcOffset(7).format('MM/DD/YYYY');
    a = a.split('/');
    return `${a[0]}/${a[1]}`
};
const calcPositions = (dateCard) => {
    let les_than_20 = 0;
    let les_than_25 = 0;
    let les_than_50 = 0;
    let les_than_100 = 0;
    dateCard.results.client_listings.forEach(result => {
        if (result.position !== 'N/A') {
            if (0 < result.position && result.position <= 20) {
                les_than_20 = les_than_20 + 1
            }
            if (0 < result.position && result.position <= 25) {
                les_than_25 = les_than_25 + 1
            }
            if (0 < result.position && result.position <= 50) {
                les_than_50 = les_than_50 + 1
            }
            if (0 < result.position && result.position <= 100) {
                les_than_100 = les_than_100 + 1
            }
        }
    });
    return {lt20: les_than_20, lt25: les_than_25, lt50: les_than_50, lt100: les_than_100}
};

const CalendarCard = ({
                          getCalendarData, loading, calendarCard, cardIndex, openWindow,
                          closeWindow, switchType, listingType
                      }) => {
    const [showMore, setShowMore] = useState(false);
    const [windowIndex, setWindowIndex] = useState(null);
    const [selectedChart, changeChart] = useState(1);

    const initAvgChartOptions = {
        title: {
            text: 'Prices data'
        },
        credits: {
            enabled: false
        },
        yAxis: [
            {
                min: 0
            },
            {
                min: 0,
                max: 200,
                opposite: true,
                zIndex: 10,
            }
        ],
        xAxis: {
            type: 'datetime',
        },
        tooltip: {shared: true},
        series: [
            {data: [{x: 1, y: 0.1}], yAxis: 1, type: 'line', color: '#afafaf'},
            {data: [{x: 1, y: 2}], yAxis: 1, type: 'line', color: 'hsla(30, 100%, 60%, 1)'},
            {data: [{x: 1, y: 0.1}], yAxis: 0, type: 'line', color: 'hsla(70, 100%, 40%, 1)'},
            {data: [{x: 1, y: 2}], yAxis: 0, type: 'line', color: 'hsla(190, 100%, 40%, 1)'},
        ]
    };
    const [avgChartData, setAvgChartData] = useState(initAvgChartOptions);

    const initOccupancyChartOptions = {
        title: {
            text: 'Occupancy data'
        },
        credits: {
            enabled: false
        },
        yAxis: [
            {
                min: 0
            },
            {
                max: 100,
                opposite: true,
                zIndex: 10,
            }
        ],
        xAxis: {
            type: 'datetime',
        },
        tooltip: {shared: true},
        series: [
            {data: [{x: 1, y: 0.1}], yAxis: 0, type: 'column', color: '#afafaf'},
            {data: [{x: 1, y: 2}], yAxis: 1, color: 'hsla(30, 100%, 60%, 1)'},
        ]
    };
    const [occupancyChartData, setOccupancyChartData] = useState(initOccupancyChartOptions);

    const initPositionsChartOptions = {
        title: {
            text: 'Position data'
        },
        credits: {
            enabled: false
        },
        yAxis: [
            {
                min: 0
            },
            {
                max: 100,
                opposite: true,
                zIndex: 10,
            }
        ],
        xAxis: {
            type: 'datetime',
        },
        tooltip: {shared: true},
        series: [
            {data: [{x: 1, y: 0.1}], yAxis: 0, type: 'column', color: '#afafaf'},
            {data: [{x: 1, y: 2}], yAxis: 1, color: 'hsla(30, 100%, 60%, 1)'},
        ]
    };
    const [positionsChartOptions, setPositionsChartOptions] = useState(initPositionsChartOptions);

    const buildChartConfig = () => {
        let localResults = {'calendar_results': []};
        let chartDataPercent = [];
        let chartDataAbs = [];
        let chartDataAvg = [];
        let chartDataMedian = [];
        let chartDataAvgTotal = [];
        let chartDataMedianTotal = [];
        let chartDataPositions = [];
        let chartDataPositionsPercent = [];
        let occupancyChartOptions = {
            title: {
                text: 'Occupancy data'
            },
            credits: {
                enabled: false
            },
            yAxis: [
                {
                    min: 0
                },
                {
                    max: 100,
                    opposite: true,
                    zIndex: 10,
                }
            ],
            xAxis: {
                type: 'datetime',
            },
            tooltip: {shared: true},
            series: [
                {data: [{x: 1, y: 0.1}], yAxis: 0, type: 'column', color: '#afafaf'},
                {data: [{x: 1, y: 2}], yAxis: 1, color: 'hsla(30, 100%, 60%, 1)'},
            ]
        };
        let avgChartOptions = {
            title: {
                text: 'Prices data'
            },
            credits: {
                enabled: false
            },
            yAxis: [
                {
                    min: 0
                },
                {
                    min: 0,
                    max: 200,
                    opposite: true,
                    zIndex: 10,
                }
            ],
            xAxis: {
                type: 'datetime',
            },
            tooltip: {shared: true},
            series: [
                {data: [{x: 1, y: 0.1}], yAxis: 1, type: 'line', color: '#afafaf'},
                {data: [{x: 1, y: 2}], yAxis: 1, type: 'line', color: 'hsla(30, 100%, 60%, 1)'},
                {data: [{x: 1, y: 0.1}], yAxis: 0, type: 'line', color: 'hsla(70, 100%, 40%, 1)'},
                {data: [{x: 1, y: 2}], yAxis: 0, type: 'line', color: 'hsla(190, 100%, 40%, 1)'},
            ]
        };
        let positionsChartOptions = {
            title: {
                text: 'Position data'
            },
            credits: {
                enabled: false
            },
            yAxis: [
                {
                    min: 0
                },
                {
                    max: 100,
                    opposite: true,
                    zIndex: 10,
                }
            ],
            xAxis: {
                type: 'datetime',
            },
            tooltip: {shared: true},
            series: [
                {data: [{x: 1, y: 0.1}], yAxis: 0, type: 'column', color: '#afafaf'},
                {data: [{x: 1, y: 2}], yAxis: 1, color: 'hsla(30, 100%, 60%, 1)'},
            ]
        };
        let chartResultsCopy = JSON.stringify(calendarCard);
        chartResultsCopy = JSON.parse(chartResultsCopy);
        chartResultsCopy.calendar_results.forEach((day) => {
            let results_list = [];
            day.results.client_listings.forEach(listing => {
                results_list.push(listing)
            });
            day.results.others_listings.forEach(listing => {
                results_list.push(listing)
            });

            let uniqueResults = results_list.reduce((unique, o) => {
                if (!unique.some(obj => obj.listing_nick === o.listing_nick)) {
                    unique.push(o)
                }
                return unique
            }, []);
            delete day['results'];
            day['results'] = uniqueResults;

            let available_listings = 0;
            let above_limit = -1;
            let border_limit = 20;

            uniqueResults.forEach((result) => {
                if (result.position !== 'N/A' && result.position <= border_limit) {
                    above_limit++;
                }
                available_listings = available_listings + 1
            });

            day['available_listings'] = available_listings
            day['above_limit'] = above_limit
            day['above_limit_ratio'] = above_limit / day.available_listings_ng * 100


            day['available_listings_ratio'] = (day['available_listings'] / day['client_listings_count']) * 100
            localResults.calendar_results.push(day)

            chartDataPercent.push({
                'y': parseInt(day.available_listings_ratio_ng), 'x': Date.parse(day.checkin)
            })
            chartDataAbs.push({
                'y': parseInt(day.available_listings_ng), 'x': Date.parse(day.checkin)
            })

            let avg = countAvg(day.daily_price_list, 25)
            let mdn = countMedian(day.daily_price_list, 30)
            let avgTotal = countAvg(day.total_price_list, 25)
            let mdnTotal = countMedian(day.total_price_list, 30)

            chartDataAvg.push({
                'y': parseInt(avg), 'x': Date.parse(day.checkin)
            })
            chartDataMedian.push({
                'y': parseInt(mdn), 'x': Date.parse(day.checkin)
            })
            chartDataAvgTotal.push({
                'y': parseInt(avgTotal), 'x': Date.parse(day.checkin)
            })
            chartDataMedianTotal.push({
                'y': parseInt(mdnTotal), 'x': Date.parse(day.checkin)
            })

            chartDataPositions.push({
                'y': parseInt(above_limit), 'x': Date.parse(day.checkin)
            })

            chartDataPositionsPercent.push({
                'y': parseInt(day.above_limit_ratio), 'x': Date.parse(day.checkin)
            })

        });

        occupancyChartOptions.series[1].data = chartDataPercent;
        occupancyChartOptions.series[1].name = '%';
        occupancyChartOptions.series[0].data = chartDataAbs;
        occupancyChartOptions.series[0].name = 'Absolute';

        avgChartOptions.series[0].data = chartDataAvg;
        avgChartOptions.series[0].name = 'avg (daily)';
        avgChartOptions.series[1].data = chartDataMedian;
        avgChartOptions.series[1].name = 'median (daily)';
        avgChartOptions.series[2].data = chartDataAvgTotal;
        avgChartOptions.series[2].name = 'avg (total)';
        avgChartOptions.series[3].data = chartDataMedianTotal;
        avgChartOptions.series[3].name = 'median (total)';

        positionsChartOptions.series[0].data = chartDataPositions;
        positionsChartOptions.series[0].name = 'count';
        positionsChartOptions.series[1].data = chartDataPositionsPercent;
        positionsChartOptions.series[1].name = '%';

        setAvgChartData(avgChartOptions);
        setOccupancyChartData(occupancyChartOptions);
        setPositionsChartOptions(positionsChartOptions);
        return [occupancyChartOptions, avgChartOptions, positionsChartOptions]

    };

    const handleOpenModalWindow = (index) => {
        setShowMore(true);
        setWindowIndex(index)
    };

    const selectNewRange = e => {
        let range = e.target.value;
        let chartDataAvg = [];
        let chartDataMedian = [];
        let chartDataAvgTotal = [];
        let chartDataMedianTotal = [];
        let chartResultsCopy = JSON.stringify(calendarCard);
        chartResultsCopy = JSON.parse(chartResultsCopy);
        chartResultsCopy.calendar_results.forEach((day) => {
            let results_list = [];
            day.results.client_listings.forEach(listing => {
                results_list.push(listing)
            });
            day.results.others_listings.forEach(listing => {
                results_list.push(listing)
            });

            let uniqueResults = results_list.reduce((unique, o) => {
                if (!unique.some(obj => obj.id === o.id)) {
                    unique.push(o)
                }
                return unique
            }, []);
            delete day['results'];
            day['results'] = uniqueResults;

            let avg = countAvg(day.daily_price_list, range);
            let mdn = countMedian(day.daily_price_list, range);
            let avgTotal = countAvg(day.total_price_list, range);
            let mdnTotal = countMedian(day.total_price_list, range);

            chartDataAvg.push({
                'y': parseInt(avg), 'x': Date.parse(day.checkin)
            });
            chartDataMedian.push({
                'y': parseInt(mdn), 'x': Date.parse(day.checkin)
            });
            chartDataAvgTotal.push({
                'y': parseInt(avgTotal), 'x': Date.parse(day.checkin)
            });
            chartDataMedianTotal.push({
                'y': parseInt(mdnTotal), 'x': Date.parse(day.checkin)
            })
        });
        let newData = JSON.stringify(avgChartData);
        newData = JSON.parse(newData);
        newData.series[0].data = chartDataAvg;
        newData.series[0].name = 'avg (daily)';
        newData.series[1].data = chartDataMedian;
        newData.series[1].name = 'median (daily)';
        newData.series[2].data = chartDataAvgTotal;
        newData.series[2].name = 'avg (total)';
        newData.series[3].data = chartDataMedianTotal;
        newData.series[3].name = 'median (total)';
        setAvgChartData(newData)
    };

    const selectLimit = e => {
        const limit = e.target.value;
        let chartDataPositions = [];
        let chartDataPositionsPercent = [];

        let chartResultsCopy = JSON.stringify(calendarCard);
        chartResultsCopy = JSON.parse(chartResultsCopy);
        chartResultsCopy.calendar_results.forEach((day) => {
            let results_list = [];
            day.results.client_listings.forEach(listing => {
                results_list.push(listing)
            });
            day.results.others_listings.forEach(listing => {
                results_list.push(listing)
            });

            let uniqueResults = results_list.reduce((unique, o) => {
                if (!unique.some(obj => obj.listing_nick === o.listing_nick)) {
                    unique.push(o)
                }
                return unique
            }, []);
            delete day['results'];
            day['results'] = uniqueResults;


            let above_limit = -1;

            let border_limit = parseInt(limit);

            uniqueResults.forEach((result) => {
                if (result.position !== 'N/A' && result.position <= border_limit) {
                    above_limit++;
                }
            });

            day['above_limit'] = above_limit;
            day['above_limit_ratio'] = above_limit / day.available_listings_ng * 100;


            chartDataPositions.push({
                'y': parseInt(above_limit), 'x': Date.parse(day.checkin)
            });

            chartDataPositionsPercent.push({
                'y': parseInt(day.above_limit_ratio), 'x': Date.parse(day.checkin)
            })

        });

        let newData = JSON.stringify(positionsChartOptions);
        newData = JSON.parse(newData);
        newData.series[0].data = chartDataPositions;
        newData.series[1].data = chartDataPositionsPercent;
        setPositionsChartOptions(newData)

    };

    useEffect(() => {
        getCalendarData();
    }, [getCalendarData]);

    useEffect(() => {
        if (calendarCard !== null) {

            calendarCard.calendar_results.forEach((day) => {
                let uniqueResults = [];
                day.results.client_listings.forEach((listing) => {
                    let res = R.find(R.propEq('listing_nick', listing.listing_nick), uniqueResults);
                    if (typeof res === "undefined"){
                        uniqueResults.push(listing)
                    }
                });
                day.results.client_listings = uniqueResults;
            });

            buildChartConfig()
        }
    }, [calendarCard]);

    const renderCalendarCard = () => {
        if (loading) return (
            <div className="preloader-icon">
                <svg version="1.1" id="L9" width="64px" xmlns="http://www.w3.org/2000/svg" fill="#1e2e3f" x="0px"
                     y="0px" viewBox="0 0 100 100" enableBackground="new 0 0 0 0" xmlSpace="preserve">
                    <path fill="#1e2e3f"
                          d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
                          transform="rotate(309.97 50 50)">
                        <animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s"
                                          from="0 50 50" to="360 50 50" repeatCount="indefinite"></animateTransform>
                    </path>
                </svg>
            </div>
        );
        if (calendarCard !== null) {
            return (
                <div className="calendar-card-wrapper">
                    <div className="calendar-card-meta">
                        <div className="positions-count top-20">
                            <span className='text-value'>Top 20 <span className="legend">listings</span></span>
                        </div>
                        <div className="positions-count top-25">
                            <span className='text-value'>Top 25 <span className="legend">listings</span></span>
                        </div>
                        <div className="positions-count top-50">
                            <span className='text-value'>Top 50 <span className="legend">listings</span></span>
                        </div>
                        <div className="positions-count top-100">
                            <span className='text-value'>Top 100 <span className="legend">listings</span></span>
                        </div>
                    </div>
                    <div className="calendar-container">
                        {
                            showMore ?
                                <div className="day-details">
                            <span className="close-modal"
                                  onClick={() => setShowMore(false)}>x</span>
                                    <h3>Details for check-in
                                        date: {calendarCard.calendar_results[windowIndex].checkin}</h3>

                                    <div className="card-body">
                                        <div className="type-switchers">
                                            <a onClick={() => switchType(0)}
                                               className={"switcher-item-left switcher-item " + (listingType === 0 || typeof listingType === 'undefined' ? 'active' : null)}>My</a>
                                            <a onClick={() => switchType(1)}
                                               className={"switcher-item-right switcher-item " + (listingType === 1 ? 'active' : null)}>Others</a>
                                        </div>

                                        {listingType === 0 || typeof listingType === 'undefined' ?
                                            <div className="card-body-list">
                                                <ul className="listings-list">
                                                    {calendarCard.calendar_results[windowIndex].results.client_listings.map(result => {
                                                        return (
                                                            <li className="list-item client-item">
                                                                <span
                                                                    className="listing-position">{result.position}</span>
                                                                {
                                                                    typeof result.z_index !== 'undefined' && result.z_index !== null ?
                                                                        <span
                                                                            className="listing-name">{result.listing_nick} / {result.avg_rating} / ${result.price_per_night} / ${result.total_price} / {result.z_index.toFixed(2)}</span> :
                                                                        <span
                                                                            className="listing-name">{result.listing_nick}</span>
                                                                }
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                            </div> :

                                            <ul className="listings-list">
                                                {calendarCard.calendar_results[windowIndex].results.others_listings.map(result => {
                                                    return (
                                                        <li className="list-item others-item">
                                                            <span className="listing-position">{result.position}</span>
                                                            <span
                                                                className="listing-name">{result.name} / {result.avg_rating} / ${result.price_per_night} / ${result.total_price}</span>
                                                        </li>
                                                    )
                                                })}
                                            </ul>

                                        }
                                    </div>

                                </div> :
                                null
                        }

                        {calendarCard.calendar_results.map((dateCard, dateIndex) => {
                            const dateResults = calcPositions(dateCard);
                            return (
                                <>
                                    <div className="day-card" key={dateCard.checkin}
                                         onClick={() => handleOpenModalWindow(dateIndex)}>
                                        <div className="card-header">
                                            <div className="current-date">
                                                <span className="date-name">{handleDate(dateCard.checkin)}</span>
                                                <span className="day-name">{getDayName(dateCard.checkin)}</span>
                                            </div>
                                            <div className="available-count">
                            <span className='text-value'>{dateCard.available_listings_ng}
                                ({dateCard.available_listings_ratio_ng.toFixed(0)}%)</span>
                                                <span className='text-label'>Av. lists</span>
                                            </div>
                                        </div>
                                        <div className="card-header">
                                            <div className="prices-count daily">
                                            <span
                                                className='text-value'>${countAvg(dateCard.daily_price_list, 25)}</span>
                                                <span
                                                    className='text-label'>${countMedian(dateCard.daily_price_list, 30)}</span>
                                            </div>
                                            <div className="prices-count total">
                                            <span
                                                className='text-value'>${countAvg(dateCard.total_price_list, 25)}</span>
                                                <span
                                                    className='text-label'>${countMedian(dateCard.total_price_list, 30)}</span>
                                            </div>
                                        </div>
                                        <div className="card-header header--positions-count">
                                            <div className="positions-count top-20">
                                                <span className='text-value'>{dateResults.lt20}
                                                ({(dateResults.lt20 / dateCard.available_listings * 100).toFixed(0)})%
                                                </span>
                                            </div>
                                            <div className="positions-count top-25">
                                                <span className='text-value'>{dateResults.lt25}
                                                ({(dateResults.lt25 / dateCard.available_listings * 100).toFixed(0)})%
                                                </span>
                                            </div>
                                            <div className="positions-count top-50">
                                                <span className='text-value'>{dateResults.lt50}
                                                ({(dateResults.lt50 / dateCard.available_listings * 100).toFixed(0)})%
                                                </span>
                                            </div>
                                            <div className="positions-count top-100">
                                                <span className='text-value'>{dateResults.lt100}
                                                ({(dateResults.lt100 / dateCard.available_listings * 100).toFixed(0)})%
                                                </span>
                                            </div>
                                        </div>
                                        <div className="client-listings">
                                            <ul className="listings-list">
                                                {dateCard.results.client_listings.map(result => {
                                                    return (
                                                        <li className="list-item client-item">
                                                            <span className="listing-position">{result.position}</span>
                                                            {
                                                                typeof result.z_index !== 'undefined' && result.z_index !== null ?
                                                                    <span
                                                                        className="listing-name">{result.listing_nick} / {result.avg_rating} / ${result.price_per_night} / ${result.total_price} / {result.z_index.toFixed(2)}</span> :
                                                                    <span
                                                                        className="listing-name">{result.listing_nick}</span>
                                                            }
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                </>

                            )
                        })}
                    </div>
                </div>
            )
        }
        return <p>error</p>;
    };

    let currentChart;
    if (selectedChart === 1) {
        currentChart = <HighchartsReact highcharts={Highcharts} options={occupancyChartData}/>
    }
    else if (selectedChart === 2) {
        currentChart =
            <>
                <div className="chart-selector">
                    <div className="chart-selector-wrapper">
                        <p>Range:</p>
                        <select onChange={selectNewRange} className="form-control-sm">
                            <option defaultValue>25</option>
                            <option>30</option>
                            <option>60</option>
                            <option>100</option>
                        </select>
                    </div>
                </div>
                <HighchartsReact highcharts={Highcharts} options={avgChartData}/>
            </>
    }
    else {
        currentChart =
            <>
                <div className="chart-selector">
                    <div className="chart-selector-wrapper">
                        <p>Border:</p>
                        <select onChange={selectLimit} className="form-control-sm">
                            <option defaultValue>20</option>
                            <option>25</option>
                            <option>50</option>
                            <option>100</option>
                        </select>
                    </div>
                </div>
                <HighchartsReact highcharts={Highcharts} options={positionsChartOptions}/>
            </>
    }

    const chartsBlock =
        <div className="charts-container">
            <div className="charts-switcher">
                <div className="charts-switcher-right">
                    <a className={"switcher-item-left switcher-item "}
                       onClick={() => changeChart(1)}>Occupancy </a>
                    <a className={"switcher-item-middle switcher-item "}
                       onClick={() => changeChart(2)}>Prices </a>
                    <a className={"switcher-item-right switcher-item "}
                       onClick={() => changeChart(3)}>Positions </a>
                </div>
            </div>
            <div className="chart-wrapper">
                {currentChart}
            </div>
        </div>

    return (
        <div>
            <HeaderBlock/>
            <section>
                {!loading && calendarCard ? chartsBlock : null}
                {renderCalendarCard()}
            </section>
        </div>
    )
};

const mapDispatchToProps = (dispatch) => {
    const calendarID = window.location.pathname.split('/')[2];
    return {
        getCalendarData: () => dispatch(fetchCalendar(calendarID)),
        openWindow: cardIndex => dispatch(handleCalendarIndex(cardIndex)),
        closeWindow: () => dispatch(handleCalendarIndex(null)),
        switchType: listingType => dispatch(handleListingsTypes(listingType))
    }
};

const mapStateToProps = state => ({
    loading: state.calendarCard.loading,
    calendarCard: state.calendarCard.calendarCard,
    hasErrors: state.calendarCard.hasErrors,
    cardIndex: state.calendarCard.cardIndex,
    listingType: state.calendarCard.listingType,
});

export default connect(mapStateToProps, mapDispatchToProps)(CalendarCard)
