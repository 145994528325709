import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { fetchTasks } from "../../../actions/tasksActions";
import HeaderBlock from "../../../сomponents/Header";
import PreloaderBlock from "../../../сomponents/Preloader"
import { TasksListItem } from './TasksListItem'


const TasksList = ({dispatch, loading, tasks, hasErrors}) => {
    useEffect(() => {
        dispatch(fetchTasks())
    }, [dispatch]);
    const renderTasks = () => {
        if (loading) return (
            <PreloaderBlock/>
        );
        if (hasErrors) return <p>error</p>;
        return (
            <div>
                <div className="header-toolbox">
                    <h1>Tasks list</h1>
                </div>
                <div className="bordered-container">
                    <table className="calendars-list-table">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Created</th>
                                <th>Status</th>
                                <th>Execution time</th>
                            </tr>
                        </thead>
                        <tbody>
                        {tasks.map(task => {
                            return (
                                <TasksListItem key={task.id} task={task}/>
                            )
                        })}
                        </tbody>
                    </table>
                </div>
            </div>
        )

    };
    return (
        <div>
            <HeaderBlock/>
            <section className="main-section">
                {renderTasks()}
            </section>
        </div>
    )
};

const mapStateToProps = state => ({
  loading: state.tasks.loading,
  tasks: state.tasks.tasks,
  hasErrors: state.tasks.hasErrors,
});

export default connect(mapStateToProps)(TasksList)
