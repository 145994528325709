import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {fetchListingChartData} from "../../actions/listingsActions";
import HeaderBlock from "../../сomponents/Header";
import PreloaderBlock from "../../сomponents/Preloader"
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'


const ListingsList = ({dispatch, loading, chartData, hasErrors}) => {
    const listingID = window.location.pathname.split('/')[2];
    const days = window.location.pathname.split('&')[1].split('=')[1];
    console.log(days)

    useEffect(() => {
        dispatch(fetchListingChartData(listingID, days));
    }, [dispatch]);


    const buildChart = () => {
        if (chartData) {
            const buildChartConfig = () => {
                let chartOptions = {
                    chart: {
                        type: 'column'
                    },
                    title: {
                        text: 'Positions data'
                    },
                    yAxis: [
                        {
                            min: 0,
                            max: 150,
                        },
                        {
                            max: 150,
                            opposite: true,
                            zIndex: 10,
                        }
                    ],
                    xAxis: {
                        allowDecimals: false,
                        type: 'datetime',
                        title: {
                            text: ''
                        },
                        minPadding: 0,
                        maxPadding: 0,
                        startOnTick: false,
                        endOnTick: false,
                        labels: {
                            step: 1,
                            style: {
                                color: '#525151',
                            },
                            formatter: function () {
                                return Highcharts.dateFormat("%m/%e", this.value);
                            }
                        },
                        lineWidth: 2
                    },
                    tooltip: {shared: true},
                    plotOptions: {
                        line: {
                            dataLabels: {
                                enabled: true
                            },

                        }
                    },
                    series: [
                        {
                            data: [{x: 1, y: 0.1}],
                            yAxis: 0,
                            marker: {symbol: 'circle'},
                            type: 'line',
                            color: 'hsla(30, 100%, 60%, 1)',
                            name: 'position'
                        }
                    ]
                };

                if (chartData.status === '01') {
                    console.log('No data for chart');
                } else {
                    let chartSeries = [];
                    let ratingSeries = [];
                    chartData.forEach(item => {
                        let position = item.position;
                        if (position === 'N/A') {
                            position = 0
                        }
                        chartSeries.push({
                            x: new Date(item.checkin.replace(' 00:00:00', '')),
                            y: position
                        });
                        ratingSeries.push({
                            x: new Date(item.checkin.replace(' 00:00:00', '')),
                            y: item.avg_rating
                        })
                    });
                    chartOptions.series[0].data = chartSeries;
                }

                return chartOptions
            };
            const currentChart = <HighchartsReact highcharts={Highcharts} options={buildChartConfig()}/>;

            return (
                <div className="charts-container">
                    <div className="chart-wrapper">
                        {currentChart}
                    </div>
                </div>

            )
        }
    };

    const btns = ['1 day', '2 day', '3 day'];


    const renderListings = () => {
        if (loading) return (
            <PreloaderBlock/>
        );
        if (!loading && !hasErrors) {
            return (
                <div>
                    <div className="header-toolbox">
                        <h1>Chart for listing</h1>
                        <div>
                            {btns.map((btn, btnIndex) => {
                                const link = `${window.location.href.split('days_stay')[0]}days_stay=${btnIndex+1}`;
                                if (btnIndex + 1 === parseInt(days)) {
                                    return <a href={link} className="btn btn-yellow mr-1">{btn}</a>
                                } else {
                                    return <a href={link} className="btn btn-gray mr-1">{btn}</a>
                                }
                            })}
                        </div>
                    </div>
                    <div className="bordered-container">
                        {buildChart()}
                    </div>
                </div>
            )
        }
        else return <p>error</p>;
    };
    return (
        <div>
            <HeaderBlock/>
            <section className="main-section">
                {renderListings()}
            </section>
        </div>
    )
};

const mapStateToProps = state => ({
    loading: state.listingsCharts.loading,
    chartData: state.listingsCharts.chartData,
    hasErrors: state.listingsCharts.hasErrors,
});

export default connect(mapStateToProps)(ListingsList)
