import React, {useState} from 'react'
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import moment from 'moment'

const CalendarsFilter = ({calendars, doFiltration, datesChanged, dates}) => {
    const [value, onChange] = useState(dates);
    const [formData, addCondition] = useState([]);
    const [showHelp, setHelp] = useState(false);

    const helpBlock = <div className='filters-info-block'> <p>Query / Days / Guests / Pets</p> </div>;

    const handleDateChange = (value) => {
        let dateFrom = moment(value[0]).format('YYYY-MM-DD');
        let dateTo = moment(value[1]).format('YYYY-MM-DD');
        const arr = [...formData];

        addCondition(arr);
        onChange(value);
        localStorage.setItem('dates', JSON.stringify([dateFrom, dateTo]));
        datesChanged({'dateFrom': dateFrom, 'dateTo': dateTo})
    };

    return (
        <div className="tools-wrapper">
            <div className="filter-selector">
                <span style={{lineHeight: "30px", fontSize: "12px", position: 'relative'}}>Select group
                    <span className="filters-info" onMouseEnter={() => setHelp(true)} onMouseLeave={() => setHelp(false)}>
                        ?
                    </span>
                    {showHelp ? helpBlock : null}
                </span>
                <select onChange={doFiltration} className="form-control-sm">
                    <option defaultValue>all groups</option>
                    {calendars.calendar_groups.map((group, index) => {
                        return <option key={index} value={group}>{group}</option>
                    })}
                </select>
            </div>
            <div className="dates-selector">
                <span style={{lineHeight: "30px", fontSize: "12px"}}>Select dates</span>
                <DateRangePicker
                    onChange={(value) => handleDateChange(value)}
                    value={dates}
                    clearIcon={false}
                    calendarIcon={false}
                />
            </div>
        </div>
    )
};

export default CalendarsFilter

