import React from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import CalendarsList from './views/calendars/CalendarsList'
import ListingsList from './views/listings/ListingsList'
import ListingChart from './views/listings/ListingChart'
import ListingsGroups from './views/listings/ListingsGroups'
import ListingsGroupsSMS from './views/listings/ListingsGroupsSMS'
import CalendarCard from './views/calendars/CalendarCard'
import SchedulerSettings from './views/settings/sheduler/SchedulerSettings'
import EditSchedulerSettings from './views/settings/sheduler/EditSchedulerSettings'
import ProxiesList from './views/settings/sheduler/ProxiesList'
import TasksList from './views/settings/sheduler/TasksList'
import AdminSettings from './views/settings/AdminSettings'
import SignIn from './views/auth/SignIn'
import PrivateRoute from "./utils/PrivateRoute";
import {AuthProvider} from "./utils/Auth";

const App = () => {
  return(
      <AuthProvider>
          <Router>
              <Switch>
                  <PrivateRoute exact path="/" component={CalendarsList} />
                  <PrivateRoute exact path="/calendars" component={CalendarsList} />
                  <PrivateRoute exact path="/listings" component={ListingsList} />
                  <PrivateRoute exact path="/listing/:listingID" component={ListingChart} />
                  <PrivateRoute exact path="/groups" component={ListingsGroups} />
                  <PrivateRoute exact path="/scheduler" component={SchedulerSettings} />
                  <PrivateRoute exact path="/proxies" component={ProxiesList} />
                  <PrivateRoute exact path="/tasks" component={TasksList} />
                  <PrivateRoute exact path="/settings" component={AdminSettings} />

                  <PrivateRoute exact path="/calendar/:calendarId" component={CalendarCard} />
                  <PrivateRoute exact path="/scheduler/:taskId" component={EditSchedulerSettings} />

                  <PrivateRoute exact path="/sms-groups" component={ListingsGroupsSMS} />

                  <Route exact path="/login" component={SignIn} />

                  <Redirect to="/"/>
              </Switch>
          </Router>
      </AuthProvider>
  )
};

export default App
