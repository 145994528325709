import React, {useState, useContext} from "react";
import app from "../../utils/firebase.js";
import { AuthContext } from "../../utils/Auth.js";
import { withRouter, Redirect } from "react-router-dom";

const SignIn = ({history}) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);

    const signInWithEmailAndPasswordHandler =
        (event, email, password) => {
            try {
                app.auth().signInWithEmailAndPassword(email, password).then(() => {
                    setError(null);
                    history.push('/')
                }).catch(err => {
                    setError(err.message);
                    console.log(err)
                });
            } catch (err) {
                console.log(err)
            }
            event.preventDefault();

        };

    const { currentUser } = useContext(AuthContext);

    const onChangeHandler = (event) => {
        const {name, value} = event.currentTarget;

        if (name === 'userEmail') {
            setEmail(value);
        }
        else if (name === 'userPassword') {
            setPassword(value);
        }
    };

    if (currentUser) {
        return <Redirect to="/"/>
    }
    return (
        <div>
            <section className="main-section">
                <div className="signin-header">
                    <h3 className="mh-logo">Rohanservices</h3>
                </div>
                <div className="signin-form">
                    <form className="signin-form-form" onSubmit={(event) => {signInWithEmailAndPasswordHandler(event, email, password)}}>
                        <div className="form-group">
                            <label>Email</label>
                            <input type="email" className="form-control" autoComplete="true"
                                   value={email} name="userEmail" onChange={onChangeHandler}/>
                        </div>
                        <div className="form-group">
                            <label>Password</label>
                            <input type="password" className="form-control"
                                   value={password} name="userPassword" onChange={onChangeHandler}/>
                        </div>
                        <button type="submit" className="btn btn-green">Sign In</button>
                        <p className="error-message">{error}</p>
                    </form>
                </div>
            </section>
        </div>
    );
};
export default withRouter(SignIn);
