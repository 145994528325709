import React from 'react'
import {Link} from 'react-router-dom'
import moment from "moment";
import 'moment-timezone';

export const CalendarsListItem = ({calendar}) => {
    const date = moment(calendar.scraping_date).tz('America/Chicago').utcOffset(-2).format('YYYY-MM-DD HH:mm');

    const hardDate = (calendar.scraping_date).split('T')
    const hardTime = moment(hardDate[1], 'HH:mm').utcOffset(-10).format('HH:mm');
    const hardTimeSub = moment(hardDate[1], 'HH:mm').subtract("hour", 5).format('HH:mm');
    // const date = moment(calendar.scraping_date).utcOffset(-10).format('YYYY-MM-DD hh:mm A');

    return (
        <tr>
            <td><Link to={`/calendar/${calendar.calendar_id}`}>{calendar.name}</Link></td>
            <td>{calendar.query}</td>
            <td>{calendar.days_count}</td>
            <td>{calendar.guests_number}</td>
            <td>{calendar.pets_allowed}</td>
            <td>{hardDate[0]} ({hardTimeSub})</td>
        </tr>
    )
};

