import * as actions from '../actions/listingsActions'

export const initialState = {
  listings: [],
  listingsGroups: [],
  listingsWithoutGroups: [],
  listingsCount: 0,
  loading: false,
  hasErrors: false,
  listingCreated: false,
  listingGroupCreated: false,
  listingGroupDeleted: false,
  listingDeleted: false
};

const listingsReducer = function(state = initialState, action) {
  switch (action.type) {
      case actions.GET_LISTINGS:
          return {...state, loading: true};
      case actions.GET_LISTINGS_SUCCESS:
        return {listings: action.payload, loading: false, hasErrors: false};
      case actions.GET_LISTINGS_FAILURE:
        return {...state, loading: false, hasErrors: true};
    default:
      return state
  }
};

const listingsGroupReducer = function(state = initialState, action) {
  switch (action.type) {
      case actions.GET_LISTINGS_GROUPS:
          return {...state, loading: true};
      case actions.GET_LISTINGS_GROUPS_SUCCESS:
        return {listingsGroups: action.payload, loading: false, hasErrors: false};
      case actions.GET_LISTINGS_GROUPS_FAILURE:
        return {...state, loading: false, hasErrors: true};
    default:
      return state
  }
};

const listingsCountReducer = function(state = initialState, action) {
  switch (action.type) {
      case actions.GET_LISTINGS_COUNT:
          return {...state, loading: true};
      case actions.GET_LISTINGS_COUNT_SUCCESS:
        return {listingsCount: action.payload, loading: false, hasErrors: false};
      case actions.GET_LISTINGS_COUNT_FAILURE:
        return {...state, loading: false, hasErrors: true};
    default:
      return state
  }
};

const createListingReducer = function(state = initialState, action) {
  switch (action.type) {
      case actions.CREATE_LISTING:
          return {...state, loading: true};
      case actions.CREATE_LISTING_SUCCESS:
        return {loading: false, hasErrors: false, listingCreated: true};
      case actions.CREATE_LISTING_FAILURE:
        return {...state, loading: false, hasErrors: true};
    default:
      return state
  }
};

const deleteListingReducer = function(state = initialState, action) {
  switch (action.type) {
      case actions.DELETE_LISTING:
          return {...state, loading: true};
      case actions.DELETE_LISTING_SUCCESS:
        return {loading: false, hasErrors: false, listingDeleted: true};
      case actions.DELETE_LISTING_FAILURE:
        return {...state, loading: false, hasErrors: true};
    default:
      return state
  }
};


const noGroupListingsReducer = function(state = initialState, action) {
  switch (action.type) {
      case actions.GET_LISTINGS_COUNT:
          return {...state, loading: true};
      case actions.GET_NO_GROUP_LISTINGS_SUCCESS:
        return {listingsWithoutGroups: action.payload, loading: false, hasErrors: false};
      case actions.GET_NO_GROUP_LISTINGS_FAILURE:
        return {...state, loading: false, hasErrors: true};
    default:
      return state
  }
};

const createListingGroupReducer = function(state = initialState, action) {
  switch (action.type) {
      case actions.CREATE_LISTING_GROUP:
          return {...state, loading: true};
      case actions.CREATE_LISTING_GROUP_SUCCESS:
        return {loading: false, hasErrors: false, listingGroupCreated: true};
      case actions.CREATE_LISTING_GROUP_FAILURE:
        return {...state, loading: false, hasErrors: true};
    default:
      return state
  }
};

const deleteListingGroupReducer = function(state = initialState, action) {
  switch (action.type) {
      case actions.DELETE_LISTING_GROUP:
          return {...state, loading: true};
      case actions.DELETE_LISTING_GROUP_SUCCESS:
        return {loading: false, hasErrors: false, listingGroupDeleted: true};
      case actions.DELETE_LISTING_GROUP_FAILURE:
        return {...state, loading: false, hasErrors: true};
    default:
      return state
  }
};

const listingsChartsReducer = function(state = initialState, action) {
  switch (action.type) {
      case actions.GET_LISTING_CHART_DATA:
          return {...state, loading: true};
      case actions.GET_LISTING_CHART_DATA_SUCCESS:
        return {chartData: action.payload, loading: false, hasErrors: false};
      case actions.GET_LISTING_CHART_DATA_FAILURE:
        return {...state, loading: false, hasErrors: true};
    default:
      return state
  }
};

export { listingsReducer, listingsGroupReducer, listingsCountReducer, deleteListingGroupReducer,
    createListingReducer, deleteListingReducer, noGroupListingsReducer, createListingGroupReducer,
    listingsChartsReducer }
